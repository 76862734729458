export const FETCH_MASTERDATA_REQUEST='FETCH_MASTERDATA_REQUEST';
export const FETCH_MASTERDATA_SUCCESS='FETCH_MASTERDATA_SUCCESS';
export const FETCH_MASTERDATA_FAILURE='FETCH_MASTERDATA_FAILURE';
export const FETCH_COUNTRIES_BY_REGION='FETCH_COUNTRIES_BY_REGION';
export const SET_REGION_NAME='SET_REGION_NAME';
export const SET_COUNTRY_NAME='SET_COUNTRY_NAME';
export const RESET_FILTERS='RESET_FILTERS';
export const SET_STATUS_DETAILS='SET_STATUS_DETAILS';
export const FETCH_PROVINCES_BY_COUNTRY='FETCH_PROVINCES_BY_COUNTRY';
export const SET_PROVINCE_NAME='SET_PROVINCE_NAME';
export const SET_AUDITSTATUS_NAME='SET_AUDITSTATUS_NAME';
export const SET_LOGGEDINUSEREMAIL='SET_LOGGEDINUSEREMAIL';
export const RESET_PROVINCES = 'RESET_PROVINCES';


