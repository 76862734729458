import calendarAdminReducer from "./calendarAdminReducer";
import {combineReducers} from "redux";
import eventReducer from './eventReducer';
import masterDataReducer from "./masterDataReducer";

const rootReducer = combineReducers({
    dataCalendarAdmin : calendarAdminReducer,
    eventReducer,
    masterDataReducer
});

export default rootReducer;