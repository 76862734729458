export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_AAD_UI_CLIENTID,
      authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
     redirectUri: window.location.origin
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read","api://06f280a8-2b18-4308-9fdd-3903a73f90b7/ECST.UAT"]
  };
  
 