import axiosInstance from "../adapters/AxiosSetup";
import {
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE,
  SET_EVENT_DATES,
  SET_SPINNER_EVENTTAB
} from "./EventActionTypes";
import { restApiEndpoints } from "../constants";

import {
  FETCH_MASTERDATA_REQUEST,
  FETCH_MASTERDATA_SUCCESS,
  FETCH_MASTERDATA_FAILURE,
  FETCH_COUNTRIES_BY_REGION,
  SET_REGION_NAME,
  SET_COUNTRY_NAME,
  SET_STATUS_DETAILS,
  RESET_FILTERS,
  SET_PROVINCE_NAME,
  FETCH_PROVINCES_BY_COUNTRY,
} from "./FetchMasterDataActionTypes";
import { CredentialsApi } from "../adapters/credetialsIApi";
import store from "../store";
import { SetCountryDetails, setProvinceName } from "./FetchMasterData";

const credentialsApi = new CredentialsApi();

export const fetchMasterDataRequest = () => {
  return {
    type: FETCH_MASTERDATA_REQUEST,
  };
};

export const fetchMasterDataSuccess = (masterdata) => {
  return {
    type: FETCH_MASTERDATA_SUCCESS,
    payload: masterdata,
  };
};
export const setEventDates = (dates) => {
  return {
    type: SET_EVENT_DATES,
    payload: dates,
  };
};

export const fetchCountriesByRegion = (masterdata) => {
  return {
    type: FETCH_COUNTRIES_BY_REGION,
    payload: masterdata,
  };
};
export const fetchProvincesByCountry = (masterdata) => {
  return {
    type: FETCH_PROVINCES_BY_COUNTRY,
    payload: masterdata,
  };
};

// export const resetProvinces=masterdata=>{
//     return {
//         type:RESET_PROVINCES,
//         payload: masterdata
//     }
// }

export const setRegionName = (masterdata) => {
  return {
    type: SET_REGION_NAME,
    payload: masterdata,
  };
};
export const setCountryDetails = (masterdata) => {
  return {
    type: SET_COUNTRY_NAME,
    payload: masterdata,
  };
};
export const setProvinceDetails = (masterdata) => {
  return {
    type: SET_PROVINCE_NAME,
    payload: masterdata,
  };
};

export const setStatusDetails = (masterdata) => {
  return {
    type: SET_STATUS_DETAILS,
    payload: masterdata,
  };
};

export const resetFilters = (masterdata) => {
  return {
    type: RESET_FILTERS,
    payload: masterdata,
  };
};

export const fetchMasterDataFailure = (error) => {
  return {
    type: FETCH_MASTERDATA_FAILURE,
    payload: error,
  };
};
export const fetchEventRequest = () => {
  return {
    type: FETCH_EVENT_REQUEST,
  };
};

export const fetchEventSuccess = (events) => {
  return {
    type: FETCH_EVENT_SUCCESS,
    payload: events,
  };
};

export const fetchEventFailure = (error) => {
  return {
    type: FETCH_EVENT_FAILURE,
    payload: error,
  };
};

export const fetchEvents = (startDate, endDate) => {
  return (dispatch) => {
    dispatch(SetEventsTabSpinner(true));
    dispatch(fetchEventRequest);
    var url =
      restApiEndpoints.baseUrl +
      restApiEndpoints.getEvents +
      "?StartDate=" +
      startDate +
      "&EndDate=" +
      endDate;
    axiosInstance
      .get(url)
      .then((response) => {
        const events = response.data;
        dispatch(fetchEventSuccess(events));
        dispatch(SetEventsTabSpinner(false));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchEventFailure(errorMsg));
        dispatch(SetEventsTabSpinner(false));
      });
  };
};
export const SetEventsTabSpinner = (IsSpinner) => {
  return {
    type: SET_SPINNER_EVENTTAB,
    payload: IsSpinner,
  };
};
export default {
  requestEvents() {
    return (dispatch) => {
        dispatch(SetEventsTabSpinner(true));
      var filter = store.getState();
      dispatch(fetchEventRequest);
      var RegionId = filter.masterDataReducer.RegionDetails.RegionId;
      var CountryId = filter.masterDataReducer.CountryDetails.CountryId;
      var ProvinceId = typeof filter.masterDataReducer.ProvinceDetails.ProvinceId == "undefined" ?
      0: filter.masterDataReducer.ProvinceDetails.ProvinceId;
      var StatusId =
        filter.masterDataReducer.ReportingStatusDetails.ReportingStatusId;
      console.log("event reducer", store.getState().eventReducer);
      var startDate = store.getState().eventReducer.dateDetails.startDate;
      var endDate = store.getState().eventReducer.dateDetails.endDate;
      var url =
        restApiEndpoints.baseUrl +
        restApiEndpoints.getEvents +
        "?StartDate=" +
        startDate +
        "&EndDate=" +
        endDate +
        "&RegionId=" +
        RegionId +
        "&CountryId=" +
        CountryId +
        "&ProvinceId=" +
        ProvinceId +
        "&ReportingStatusId=" +
        StatusId;
      axiosInstance
        .get(url)
        .then((response) => {
          const events = response.data;
          events.forEach((element) => {
            var eventdate = new Date(element.start);
            var today = new Date();

            var threeDateLater = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 3
            );
            if (element.reportingStatus == "Completed")
              element.backgroundColor = "green";
            else if (eventdate < today) element.backgroundColor = "red";
            else if (eventdate >= today && eventdate <= threeDateLater)
              element.backgroundColor = "#FFBF00";
            else if (eventdate > threeDateLater)
              element.backgroundColor = "amber";
          });
          dispatch(fetchEventSuccess(events));
           dispatch(SetEventsTabSpinner(false));
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(fetchEventFailure(errorMsg));
          dispatch(SetEventsTabSpinner(false));
        });
    };
  },
  setEventDates(startDate, endDate) {
    return async (dispatch) => {
      dispatch(
        setEventDates({
          startDate: startDate,
          endDate: endDate,
        })
      );
    };
  },
  fetchMasterData() {
    return async (dispatch) => {
      dispatch(fetchMasterDataRequest);
      const res = await credentialsApi.FetchMasterData();
      const masterdata = res.data;
      dispatch(fetchMasterDataSuccess(masterdata));
    };
  },
  SetCountryDetails(countryName) {
    return async (dispatch) => {
      var countries = store.getState().masterDataReducer.masterdata.Countries;
      var provinces = store.getState().masterDataReducer.masterdata.Provinces;
      if (countryName != "" && typeof countryName !== "undefined" && countryName != "Select") {
        var filteredcountries = countries.filter(
          (x) => x.CountryName == countryName
        )[0];
        dispatch(setCountryDetails(filteredcountries));
        var countryID = filteredcountries.CountryId;
        var filteredProvinces = store
          .getState()
          .masterDataReducer.masterdata.Provinces.filter(
            (x) => x.CountryId == countryID
          );
        dispatch(fetchProvincesByCountry(filteredProvinces));
        dispatch(
            setProvinceDetails({
              ProvinceName: "",
              ProvinceId: 0,
            }))
      } else {
        dispatch(
          setCountryDetails({
            CountryName: "",
            CountryId: 0,
          })
        );
        dispatch(fetchProvincesByCountry(provinces));
        dispatch(
            setProvinceDetails({
              ProvinceName: "",
              ProvinceId: 0,
            }))
      }
    };
  },
  SetProvinceDetails(provinceName) {
    return async (dispatch) => {
      var provinces = store.getState().masterDataReducer.masterdata.Provinces;
      if (provinceName != "" && provinceName != "Select") {
        var filteredProvinces = provinces.filter(
          (x) => x.ProvinceName == provinceName
        )[0];
        dispatch(setProvinceDetails(filteredProvinces));
      } else {
        dispatch(
          setProvinceDetails({
            ProvinceName: "",
            ProvinceId: 0,
          })
        );
      }
    };
  },
  SetStatusDetails(statusName) {
    return async (dispatch) => {
      var statuses =
        store.getState().masterDataReducer.masterdata.ReportingStatuses;
      console.log("filtereds", statuses);
      if (statusName != "" && statusName != "Select") {
        var filteredstatus = statuses.filter((x) => x.Status == statusName)[0];
        dispatch(setStatusDetails(filteredstatus));
      } else {
        dispatch(
          setStatusDetails({
            Status: "",
            ReportingStatusId: 0,
          })
        );
      }
    };
  },

  SetRegionName(regionName) {
    return async (dispatch) => {
      var countries = store.getState().masterDataReducer.masterdata.Countries;
      var regions = store.getState().masterDataReducer.masterdata.Regions;
      var provinces = store.getState().masterDataReducer.masterdata.Provinces;
      if (regionName != "" && regionName != "Select") {
        console.log("regions", regions);
        var region = regions.filter((x) => x.RegionName == regionName)[0];
        dispatch(setRegionName(region));
        var filteredcountries = countries.filter(
          (x) => x.RegionId == region.RegionId
        );
        dispatch(fetchCountriesByRegion(filteredcountries));
        dispatch(setCountryDetails({ 
            CountryName:'',
            CountryId:0
        }))
        dispatch(setProvinceName({ 
            ProvinceName:'',
            ProvinceId:0
        }))
      } else {
        dispatch(
          setRegionName({
            RegionName: "",
            RegionId: 0,
          })
        );
        dispatch(fetchCountriesByRegion(countries));
        
        dispatch(setCountryDetails({
            CountryName:'',
            CountryId:0
        }))
        dispatch(fetchProvincesByCountry(provinces));
        dispatch(setProvinceName({ 
            ProvinceName:'',
            ProvinceId:0
        }))
      }
    };
  },
  ResetFilter() {
    return async (dispatch) => {
        dispatch(SetEventsTabSpinner(true));
      var startDate = store.getState().eventReducer.dateDetails.startDate;
      var endDate = store.getState().eventReducer.dateDetails.endDate;
      dispatch(
        resetFilters({
          CountryDetails: {
            CountryName: "",
            CountryId: 0,
          },
          RegionDetails: {
            RegionName: "",
            RegionId: 0,
          },
          ProvinceDetails: {
            ProvinceName: "",
            ProvinceId: 0,
          },
          ReportingStatusDetails: {
            Status: "",
            ReportingStatusId: 0,
          },
        })
      );
      var filter = store.getState();
      dispatch(fetchEventRequest);
      var RegionId = filter.masterDataReducer.RegionDetails.RegionId;
      var CountryId = filter.masterDataReducer.CountryDetails.CountryId;
      var provinceId = typeof filter.masterDataReducer.ProvinceDetails.ProvinceId == "undefined" ?
      0: filter.masterDataReducer.ProvinceDetails.ProvinceId;

      var url =
        restApiEndpoints.baseUrl +
        restApiEndpoints.getEvents +
        "?StartDate=" +
        startDate +
        "&EndDate=" +
        endDate +
        "&RegionId=" +
        RegionId +
        "&CountryId=" +
        CountryId +
        "&ProvinceId=" +
        provinceId;
      axiosInstance
        .get(url)
        .then((response) => {
          const events = response.data;
          events.forEach((element) => {
            var eventdate = new Date(element.start);
            var today = new Date();
            var threeDateLater = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 3
            );
            if (element.reportingStatus == "Completed")
              element.backgroundColor = "green";
            else if (eventdate < today) element.backgroundColor = "red";
            else if (eventdate >= today && eventdate <= threeDateLater)
              element.backgroundColor = "#FFBF00";
            else if (eventdate > threeDateLater)
              element.backgroundColor = "amber";
          });
          dispatch(fetchEventSuccess(events));
          dispatch(SetEventsTabSpinner(false));
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(fetchEventFailure(errorMsg));
          dispatch(SetEventsTabSpinner(false));
        });
    };
  },
};
