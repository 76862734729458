export const FETCH_ADMIN_REQUEST='FETCH_ADMIN_REQUEST';
export const FETCH_ADMIN_SUCCESS='FETCH_ADMIN_SUCCESS';
export const FETCH_ADMIN_FAILURE='FETCH_ADMIN_FAILURE';
export const ADD_ADMIN='ADD_ADMIN';
export const SET_DIALOG_ISOPEN='SET_DIALOG_ISOPEN';
export const SET_EDITMODE='SET_EDITMODE';
export const SET_EDITROWDATA='SET_EDITROWDATA';
export const SET_SPINNER='SET_SPINNER';
export const SET_NOTIFICATIONMSG ='SET_NOTIFICATIONMSG';
export const SET_MESSAGEBAR ="SET_MESSAGEBAR";
export const SET_ERRORNOTIFICATIONMSG = "SET_ERRORNOTIFICATIONMSG";
export const SET_ERRORMESSAGEBAR = "SET_ERRORMESSAGEBAR"
export const RESET_ADDEDITFORM = "RESET_ADDEDITFORM"
export const SET_OPENERRORSDIALOG = "SET_OPENERRORSDIALOG"
export const SET_BULKVALIDATIONERROR = "SET_BULKVALIDATIONERROR"
export const SET_ISOPEN_COPYDIALOG = "SET_ISOPEN_COPYDIALOG"
export const SET_REPORTING_YEARS = "SET_REPORTING_YEARS"
// export const FETCH_BULKVALIDATIONERROR = "FETCH_BULKVALIDATIONERROR"