import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { AuthGuardType } from "../constants";
export const AuthGuard = ({ ...props }) => {
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const onLoad = async () => {
    const currentAccount = instance.getActiveAccount();

    if (currentAccount && currentAccount.idTokenClaims["roles"]) {
      let intersection = props.roles.filter((role) =>
        currentAccount.idTokenClaims["roles"].includes(role)
      );

      if (intersection.length > 0) {
        setIsAuthorized(true);
      }
    }
  };
  const renderNotAuthContent = () => {
  
    switch (props.typeOfGuard) {
      case AuthGuardType.Control:
        return null;
        break;
      case AuthGuardType.Route:
        return (
          <div className="data-area-div">
            <h3>You are unauthorized to view this content.</h3>
          </div>
        );
        break;
       
     
        
    }
  };
  useEffect(() => {
    onLoad();
}, [instance]);
  return (<>{isAuthorized ? <div>{props.children}</div> : renderNotAuthContent()}</>);
};
