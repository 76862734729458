import {
  SET_ISOPEN_COPYDIALOG,
  FETCH_ADMIN_REQUEST,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_FAILURE,
  SET_BULKVALIDATIONERROR,
  ADD_ADMIN,
  SET_OPENERRORSDIALOG,
  SET_DIALOG_ISOPEN,
  SET_EDITMODE,
  SET_EDITROWDATA,
  SET_SPINNER,
  SET_NOTIFICATIONMSG,
  SET_MESSAGEBAR,
  SET_ERRORNOTIFICATIONMSG,
  SET_ERRORMESSAGEBAR,
  RESET_ADDEDITFORM,
  SET_REPORTING_YEARS
} from "../actions/calendarAdminActionTypes";

const initialState = {
  loading: false,
  admins: [],
  error: "",
  dialogIsOpen: false,
  isEditMode: false,
  isSpinner: false,
  notificationMsg: "",
  isMsgBar: false,
  errorNotificationMsg: "",
  isErrorMsgBar: false,
  isOpenErrorDialog: false,
  validationErrors: [],
  editRowData: {
    Id: "",
    EventName: "",
    ReportingPeriod: "",
    Region: "",
    RegionName: "",
    RegionId: 0,
    Country: "",
    Province: "",
    CompanyCode: "",
    Scheme: "",
    OrgName: "",
    ReportingFrequency: "",
    ReportingDeadline: new Date(),
    ReportingLOB: "",
    MicrosoftETACompletion: new Date(),
    MicrosoftFinalDeadline: new Date(),
    ReportingStatus: "",
    ReportingStatusId: 0,
    Location: "",
    AuditSchedule: new Date(),
    AuditStatus: "",
    Notes: "",
  },
  isOpenCopyDialog: false,
  reportingYears:[]
};

const calendarAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload,
        error: "",
        editRowData: {},
        reportingYears:[]
      };
    case FETCH_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        admins: [],
        error: action.payload,
        reportingYears:[]
      };
    case ADD_ADMIN:
      return {
        ...state,
        loading: false,
      };
    case SET_DIALOG_ISOPEN:
      return {
        ...state,
        dialogIsOpen: action.payload,
      };
    case SET_EDITMODE:
      return {
        ...state,
        isEditMode: action.payload,
      };
    case SET_EDITROWDATA:
      return {
        ...state,
        editRowData: action.payload,
      };
    case SET_SPINNER:
      return {
        ...state,
        isSpinner: action.payload,
      };
    case SET_NOTIFICATIONMSG:
      return {
        ...state,
        notificationMsg: action.payload,
      };
    case SET_MESSAGEBAR:
      return {
        ...state,
        isMsgBar: action.payload,
      };
    case SET_ERRORNOTIFICATIONMSG:
      return {
        ...state,
        errorNotificationMsg: action.payload,
      };
    case SET_ERRORMESSAGEBAR:
      return {
        ...state,
        isErrorMsgBar: action.payload,
      };
    case RESET_ADDEDITFORM:
      return {
        ...state,
        editRowData: {},
      };
    case SET_OPENERRORSDIALOG:
      return {
        ...state,
        isOpenErrorDialog: action.payload,
      };
    case SET_BULKVALIDATIONERROR:
      return {
        ...state,
        validationErrors: action.payload,
      };
    case SET_ISOPEN_COPYDIALOG:
      return {
        ...state,
        isOpenCopyDialog: action.payload,
      };
    case SET_REPORTING_YEARS:
      return {
        ...state,
        reportingYears: action.payload,
      };
    
    default:
      return state;
  }
};

export default calendarAdminReducer;
