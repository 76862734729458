import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./Authentication/AuthConfig";
import { Provider } from 'react-redux';
import store from "./store";
export const msalInstance = new PublicClientApplication(msalConfig);
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

store.subscribe(() =>console.log(store.getState()));

msalInstance.addEventCallback(event => {
if(event.eventType == EventType.LOGIN_SUCCESS){
  
  msalInstance.setActiveAccount(event.payload.account);
}
})
root.render(
   <BrowserRouter basename={baseUrl}>
      <MsalProvider instance={msalInstance}>
      <Provider store={store}>
     <App />
     </Provider>
     </MsalProvider>
   </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
