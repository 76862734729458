import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import {
  createScope,
  partnerCenterTheme,
  dialog,
  button,
  icon,
  textField,
  select,
  option,
  textArea,
  text,
} from "@harmony/enablers/react";
import {
  SetIsOpenCopyDialog,
  SetCopyEvents,
} from "../actions/calendarAdminAction";
import { useGetLoggedInUserEmail } from "../Authentication/AuthUtil";
const scope = createScope({
  reactInstance: React,
  theme: partnerCenterTheme,
});

const Dialog = scope.forReact(dialog);
const Button = scope.forReact(button);
const TextField = scope.forReact(textField);
const Select = scope.forReact(select);
const Option = scope.forReact(option);
const Text = scope.forReact(text);

const buttonContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  gap: "var(--he-spacing-x-small)",
  marginBottom: "var(--he-spacing-large)",
  justifyContent: "flex-end",
};

const CopyDialog = (props) => {
  let dispatch = useDispatch();
  const { loggedInUserEmail } = useGetLoggedInUserEmail();
  const [validationerrorsMessage,SetvalidationerrorsMessage]= useState('');
  const { isOpenCopyDialog, reportingYears } = useSelector(
    (state) => state.dataCalendarAdmin
  );
  const [formState, SetFormState] = useState();
  const onFormControlChange = (e) => {
    const { name, value } = e.target;

    SetFormState((formState) => ({
      ...formState,
      [name]: value,
    }));
  };

  useEffect(() => {
    SetFormState();
}, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(formState.ToYear <= formState.FromYear){
     SetvalidationerrorsMessage("ToYear must be greater than FromYear");
    }
    else{
      SetvalidationerrorsMessage("");
      var result = window.confirm(
        "Copying events might overwrite already exiting events in the target year. Do you want to proceed with Copy?"
      );
      if (result) {
        const loggedinuserEmail = await loggedInUserEmail();
  
        const formData = new FormData();
        formData.append("FromYear", formState.FromYear);
        formData.append("ToYear", formState.ToYear);
        formData.append("CreatedBy", loggedinuserEmail);
        dispatch(await SetCopyEvents(formData));
      }
    }
    
  };
  return (
    <Dialog
      heading="Copy Events"
      style={{ "--width": "400px" }}
      open={isOpenCopyDialog}
      onHeAfterHide={() => dispatch(SetIsOpenCopyDialog(false))}
    >
      <form onSubmit={handleSubmit}>
      <Text appearance="paragraph" style={{ color: "#e50000" }}>
         {validationerrorsMessage}
         <br/>
        </Text>

        <Text appearance="paragraph" style={{ color: "#e50000" }}>
          * Field is mandatory.
        </Text>
        <Select
          label="FromYear"
          autocomplete="both"
          name="FromYear"
          onHeChange={onFormControlChange}
          // ref={regionRef}
          required
        >
          {reportingYears.length > 0
            ? reportingYears.map((year) => {
                return <Option key={year}>{year}</Option>;
              })
            : null}
        </Select>
        <TextField
          label="ToYear"
          name="ToYear"
          onHeChange={onFormControlChange}
          // ref={schemeRef}
          required
          // maxlength = "35"
        />
        <div slot="footer" style={{ marginTop: "20px" }}>
          <div style={buttonContainerStyle}>
            <Button appearance="primary" type="submit">
              Copy
            </Button>

            <Button
              onClick={() => {
                dispatch(SetIsOpenCopyDialog(false));
              }}
            >
              Close
            </Button>
          </div>
        </div>{" "}
      </form>
    </Dialog>
  );
};
export default CopyDialog;
