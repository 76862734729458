import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger'
import thunk from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import axios from "axios";
import rootReducer from './reducers/index'


// const middleware = [thunk];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(rootReducer,  composeEnhancers(applyMiddleware(...middleware)));

const store = createStore(rootReducer,  composeWithDevTools(applyMiddleware(logger, thunk)));

export default store;


