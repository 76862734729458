import Home from "./pages/home"
import Calendar from "./pages/calendar"
import Credential from "./pages/credential"

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/calendar',
    element: <Calendar />
  },
  {
    path: '/credential',
    element: <Credential />
  }
];

export default AppRoutes;
