import {FETCH_MASTERDATA_REQUEST,FETCH_MASTERDATA_SUCCESS,FETCH_MASTERDATA_FAILURE,FETCH_COUNTRIES_BY_REGION,SET_REGION_NAME,SET_COUNTRY_NAME,FETCH_PROVINCES_BY_COUNTRY,SET_PROVINCE_NAME,SET_AUDITSTATUS_NAME,SET_STATUS_DETAILS, SET_LOGGEDINUSEREMAIL} from './FetchMasterDataActionTypes'
import {CredentialsApi} from '../adapters/credetialsIApi';
import store from '../store';
const credentialsApi = new CredentialsApi();


export const fetchMasterDataFailure=error=>{
    return {
        type:FETCH_MASTERDATA_FAILURE,
        payload: error
    }
}

export const fetchMasterDataRequest=()=>{
    return {
        type:FETCH_MASTERDATA_REQUEST
    }
}

export const fetchMasterDataSuccess=masterdata=>{
    return {
        type:FETCH_MASTERDATA_SUCCESS,
        payload: masterdata
    }
}
export const fetchCountriesByRegion=masterdata=>{
    return {
        type:FETCH_COUNTRIES_BY_REGION,
        payload: masterdata
    }
}

export const fetchProvincesByCountry=masterdata=>{
    return {
        type:FETCH_PROVINCES_BY_COUNTRY,
        payload: masterdata
    }
}

export const setRegionName=masterdata=>{
    return {
        type:SET_REGION_NAME,
        payload: masterdata
    }
}

export const setCountryDetails=masterdata=>{
    return{
        type:SET_COUNTRY_NAME,
        payload:masterdata
    }
}
export const setProvinceDetails=masterdata=>{
    return{
        type:SET_PROVINCE_NAME,
        payload:masterdata
    }
}
export const setProvinceName=masterdata=>{
    return{
        type:SET_PROVINCE_NAME,
        payload:masterdata
    }
}

export const setReportingStatusDetails=masterdata=>{
    return{
        type:SET_STATUS_DETAILS,
        payload:masterdata
    }
}

export const setAuditStatusDetails=masterdata=>{
    return{
        type:SET_AUDITSTATUS_NAME,
        payload:masterdata
    }
}

export const SetLoggedInUserEmail = email => {
    return {
        type: SET_LOGGEDINUSEREMAIL,
        payload:email
    }
}

export const fetchMasterData=()=>{
    return async (dispatch)=>{
        dispatch(fetchMasterDataRequest)
        const res = await credentialsApi.FetchMasterData();
        const masterdata=res.data
        dispatch(fetchMasterDataSuccess(masterdata))
    }
}

export const SetCountryDetails = (countryName) => {
    return async (dispatch)=>{
        var countries=store.getState().masterDataReducer.masterdata.Countries;
        var provinces=store.getState().masterDataReducer.masterdata.Provinces;
        if(countryName!=''){
            var filteredcountries = countries.filter(
                (x) => x.CountryName == countryName
            )[0];
            console.log('filteredcountries',filteredcountries);
            dispatch(setCountryDetails(filteredcountries))
            var filteredprovinces = provinces.filter(
                (x) => x.CountryId == filteredcountries.CountryId
            );
            console.log('filteredprovinces',filteredprovinces);
            dispatch(fetchProvincesByCountry(filteredprovinces))
        }
        else{
            dispatch(setCountryDetails({
                CountryName:'',
                CountryId:0
            }))
            dispatch(fetchProvincesByCountry(provinces))
        }
    }
}


export const SetRegionName = (regionName) => {
    return async (dispatch)=>{
        var countries=store.getState().masterDataReducer.masterdata.Countries;
        var regions=store.getState().masterDataReducer.masterdata.Regions;
        if(regionName!=''){

            console.log('regions',regions);
            var region=regions.filter(
                (x) => x.RegionName == regionName
            )[0];
            console.log('regionId',regionName);
            console.log('region',region);
            dispatch(setRegionName(region));
            var filteredcountries = countries.filter(
                (x) => x.RegionId == region.RegionId
            );
            console.log('filteredcountries',filteredcountries);
            dispatch(fetchCountriesByRegion(filteredcountries))
        }else{
            dispatch(setRegionName({
                RegionName:'',
                RegionId:0
            }))
            dispatch(fetchCountriesByRegion(countries))
        }
    }   
}

export const SetProvinceDetails = (provinceName) => {
    return async (dispatch)=>{
        var provinces=store.getState().masterDataReducer.masterdata.Provinces;
        if(provinceName!=''){
            var province = provinces.filter(
                (x) => x.ProvinceName == provinceName
            )[0];
            console.log('province',province);
            dispatch(setProvinceName(province))
        }
        else{
            dispatch(setProvinceName({
                ProvinceName:'',
                ProvinceId:0
            }))
        }
    }
}

export const  SetReportingStatusDetails = (statusName) => {
    return async (dispatch)=>{
        var reportingStatuses=store.getState().masterDataReducer.masterdata.ReportingStatuses;
        console.log('filtereds',reportingStatuses);
        if(statusName!=''){
            var filteredstatus = reportingStatuses.filter(
                (x) => x.Status == statusName
            )[0];
            dispatch(setReportingStatusDetails(filteredstatus))
        }
        else{
            dispatch(setReportingStatusDetails({
                Status:'',
                ReportingStatusId:0
            }))
        }
    }
}

export const  SetAuditStatusDetails = (statusName) => {
    return async (dispatch)=>{
        var auditStatuses=store.getState().masterDataReducer.masterdata.AuditStatuses;
        console.log('filtereds',auditStatuses);
        if(statusName!=''){
            var filteredstatus = auditStatuses.filter(
                (x) => x.Status == statusName
            )[0];
            dispatch(setAuditStatusDetails(filteredstatus))
        }
        else{
            dispatch(setAuditStatusDetails({
                Status:'',
                AuditStatusId:0
            }))
        }
    }
}
