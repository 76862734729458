
export const getDefaultCredentialFormData = function () {
        return {
                CorrelationId:"",
                Region:"",
                Country:"",
                Province:"",
                Scheme:"",
                LOB:"",
                UserId:"",
                Password:"",
                Url:"",
                Email:"",
                Notes:""               
        };
    }

    