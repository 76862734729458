import {
  FETCH_MASTERDATA_REQUEST,
  FETCH_MASTERDATA_SUCCESS,
  FETCH_MASTERDATA_FAILURE,
  FETCH_COUNTRIES_BY_REGION,
  SET_REGION_NAME,
  SET_COUNTRY_NAME,
  SET_STATUS_DETAILS,
  RESET_FILTERS,
  SET_PROVINCE_NAME,
  FETCH_PROVINCES_BY_COUNTRY,
  SET_AUDITSTATUS_NAME,
  SET_LOGGEDINUSEREMAIL
} from "../actions/FetchMasterDataActionTypes";

const initialState = {
  loading: false,
  masterdata: {},
  regions: [],
  countries: [],
  reportingStatuses: [],
  provinces: [],
  error: "",
  RegionDetails: {
    RegionName: "",
    RegionId: 0,
  },
  CountryDetails: {
    CountryName: "",
    CountryId: 0,
  },
  ProvinceDetails: {
    ProvinceName: "",
    ProvinceId: 0,
  },
  ReportingStatusDetails: {
    Status: "",
    ReportingStatusId: 0,
  },
  AuditStatusDetails: {
    Status: "",
    AuditStatusId: 0,
  },
  LoggedInUserEmail: "",
};

const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MASTERDATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MASTERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        masterdata: action.payload,
        regions: action.payload.Regions,
        countries: action.payload.Countries,
        provinces: action.payload.Provinces,
        reportingStatuses: action.payload.ReportingStatuses,
        auditStatuses: action.payload.AuditStatuses,
        error: "",
      };
    case FETCH_MASTERDATA_FAILURE:
      return {
        loading: false,
        masterdata: [],
        error: action.payload,
      };
    case FETCH_COUNTRIES_BY_REGION:
      return {
        ...state,
        countries: action.payload,
      };
    case FETCH_PROVINCES_BY_COUNTRY:
      return {
        ...state,
        provinces: action.payload,
      };
      
    case SET_REGION_NAME:
      return {
        ...state,
        RegionDetails: action.payload,
      };
    case SET_COUNTRY_NAME:
      return {
        ...state,
        CountryDetails: action.payload,
      };
    case SET_PROVINCE_NAME:
      return {
        ...state,
        ProvinceDetails: action.payload,
      };
    case SET_AUDITSTATUS_NAME:
      return {
        ...state,
        AuditStatusDetails: action.payload,
      };
    case RESET_FILTERS:
      return {
        ...state,
        CountryDetails: action.payload.CountryDetails,
        RegionDetails: action.payload.RegionDetails,
        ProvinceDetails: action.payload.ProvinceDetails,
        ReportingStatusDetails: action.payload.ReportingStatusDetails,
      };
    case SET_STATUS_DETAILS:
      return {
        ...state,
        ReportingStatusDetails: action.payload,
      };
    case SET_LOGGEDINUSEREMAIL:
      return {
        ...state,
        LoggedInUserEmail: action.payload,
      };
   

    default:
      return state;
  }
};

export default masterDataReducer;
