import axiosInstance from "../adapters/AxiosSetup";
import {
  FETCH_ADMIN_REQUEST,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_FAILURE,
  SET_BULKVALIDATIONERROR,
  ADD_ADMIN,
  SET_DIALOG_ISOPEN,
  SET_EDITMODE,
  SET_EDITROWDATA,
  SET_OPENERRORSDIALOG,
  SET_SPINNER,
  SET_NOTIFICATIONMSG,
  SET_MESSAGEBAR,
  SET_ERRORNOTIFICATIONMSG,
  SET_ERRORMESSAGEBAR,
  RESET_ADDEDITFORM,
  SET_ISOPEN_COPYDIALOG,
  SET_REPORTING_YEARS
} from "./calendarAdminActionTypes";
import ActionCreator from '../actions/EventAction';
import { restApiEndpoints } from "../constants";
import store from "../store";

export const fetchAdminRequest = () => {
  return {
    type: FETCH_ADMIN_REQUEST,
  };
};

export const fetchAdminSuccess = (admins) => {
  return {
    type: FETCH_ADMIN_SUCCESS,
    payload: admins,
  };
};

export const fetchAdminFailure = (error) => {
  return {
    type: FETCH_ADMIN_FAILURE,
    payload: error,
  };
};

export const AdminAdded = () => {
  return {
    type: ADD_ADMIN,
  };
};

export const SetDialogIsOpen = (IsOpen) => {
  return {
    type: SET_DIALOG_ISOPEN,
    payload: IsOpen,
  };
};

export const SetInEditMode = (IsEditMode) => {
  return {
    type: SET_EDITMODE,
    payload: IsEditMode,
  };
};

export const SetCalendarAdminSpinner = (IsSpinner) => {
  return {
    type: SET_SPINNER,
    payload: IsSpinner,
  };
};

export const SetNotificationMessage = (message) => {
  return {
    type: SET_NOTIFICATIONMSG,
    payload: message,
  };
};

export const SetIsOpenSuccessMsgBar = (isMessageBar) => {
  return {
    type: SET_MESSAGEBAR,
    payload: isMessageBar,
  };
};

export const SetIsOpenErrorsDialog = (isOpenErrorDialog) => {
  return {
    type: SET_OPENERRORSDIALOG,
    payload: isOpenErrorDialog,
  };
};

export const SetCredentailRowData = (rowdata) => {
  return {
    type: SET_EDITROWDATA,
    payload: rowdata,
  };
};

export const SetErrorNotificationMessage = (message) => {
  return {
    type: SET_ERRORNOTIFICATIONMSG,
    payload: message,
  };
};

export const SetIsOpenErrorMsgBar = (isMessageBar) => {
  return {
    type: SET_ERRORMESSAGEBAR,
    payload: isMessageBar,
  };
};
export const ResetAddEditForm = () => {
  return {
    type: RESET_ADDEDITFORM,
  };
};

export const SetBulkValidationErrors = (errors) => {
  return {
    type: SET_BULKVALIDATIONERROR,
    payload: errors,
  };
};

export const SetIsOpenCopyDialog = (isOpen) => {
  return {
    type: SET_ISOPEN_COPYDIALOG,
    payload: isOpen,
  };
};
export const SetReportingYears = (res) => {
    return {
      type: SET_REPORTING_YEARS,
      payload: res,
    };
  };

export const fetchAdmins = () => {
  return (dispatch) => {
    dispatch(SetCalendarAdminSpinner(true));
    try {
      axiosInstance
        .get(restApiEndpoints.baseUrl + restApiEndpoints.getAdminEndPoint)
        .then((response) => {
          console.log(response);
          const admins = response.data;
          dispatch(fetchAdminSuccess(admins));
          dispatch(SetCalendarAdminSpinner(false));
        });
    } catch (error) {
      const errorMsg = error.message;
      dispatch(fetchAdminFailure(errorMsg));
    } finally {
      dispatch(SetCalendarAdminSpinner(false));
    }
  };
};

export const addAdmin = (rowData) => {
  return async (dispatch) => {
    dispatch(SetCredentailRowData(rowData));
    dispatch(SetCalendarAdminSpinner(true));
    try {
      var filter = store.getState();
      var RegionId = filter.masterDataReducer.RegionDetails.RegionId;
      var CountryId = filter.masterDataReducer.CountryDetails.CountryId;
      var ProvinceId =
        filter.masterDataReducer.ProvinceDetails?.ProvinceId == undefined ||
        null
          ? 0
          : filter.masterDataReducer.ProvinceDetails?.ProvinceId;
      var ReportingStatusId =
        filter.masterDataReducer.ReportingStatusDetails.ReportingStatusId;
      var AuditStatusId =
        filter.masterDataReducer.AuditStatusDetails.AuditStatusId;
      var LoggedInUserEmail = filter.masterDataReducer.LoggedInUserEmail;

      const formData = new FormData();
      formData.append("EventName", rowData.EventName);
      formData.append("ReportingPeriod", rowData.ReportingPeriod);
      formData.append("RegionId", RegionId);
      formData.append("CountryId", CountryId);
      formData.append("ProvinceId", ProvinceId);
      formData.append("CompanyCode", rowData.CompanyCode);
      formData.append("Scheme", rowData.Scheme);
      formData.append("OrgName", rowData.OrgName);
      formData.append("ReportingFrequency", rowData.ReportingFrequency);
      formData.append("ReportingDeadline", rowData.ReportingDeadline);
      formData.append("ReportingLOB", rowData.ReportingLOB);
      formData.append("MicrosoftETACompletion", rowData.MicrosoftETACompletion);
      formData.append("MicrosoftFinalDeadline", rowData.MicrosoftFinalDeadline);
      formData.append("ReportingStatusId", ReportingStatusId);
      formData.append("Location", rowData.Location);
      formData.append("AuditSchedule", rowData.AuditSchedule);
      formData.append("AuditStatusId", AuditStatusId);
      formData.append("Notes", rowData.Notes);
      formData.append("CreatedBy", LoggedInUserEmail);
      formData.append("UpdatedBy", LoggedInUserEmail);

      const response = await axiosInstance.post(
        restApiEndpoints.baseUrl +
          restApiEndpoints.postSingleCalendarAdminEndPoint,
        formData
      );

      if (response.status === 201) {
        dispatch(AdminAdded());
        dispatch(fetchAdmins());
        dispatch(SetNotificationMessage("Added row successfully!"));
        dispatch(SetIsOpenSuccessMsgBar(true));
      } else {
        dispatch(
          SetErrorNotificationMessage(
            "Your request is not processed. Could you please try again later."
          )
        );
        dispatch(SetIsOpenErrorMsgBar(true));
      }
    } catch (err) {
      dispatch(
        SetErrorNotificationMessage(
          "Event with following combinations-" +
            rowData.Region +
            "," +
            rowData.Country +
            "," +
            rowData.Province +
            "," +
            rowData.Scheme +
            "," +
            rowData.ReportingLOB +
            " already exists!"
        )
      );
      dispatch(SetIsOpenErrorMsgBar(true));
      // if(err.response.request.status === 50){
      //     dispatch(SetErrorNotificationMessage(
      //       "Credentials with following combinations-"+ rowData.Region+ "," + rowData.Country + "," + rowData.Province + "," + rowData.Scheme + "," + rowData.ReportingLOB +" already exists!"
      //     ));
      //     dispatch(SetIsOpenErrorMsgBar(true));
      //   }
      //   else{
      //     dispatch(SetErrorNotificationMessage(
      //       "Your request is not processed. Could you please try again later."
      //     ));
      //     dispatch(SetIsOpenErrorMsgBar(true));
      //   }
    } finally {
      dispatch(SetCalendarAdminSpinner(false));
    }
  };
};

export const saveAdmin = (rowData) => {
  return async (dispatch) => {
    dispatch(SetCalendarAdminSpinner(true));
    try {
      var filter = store.getState();
      var RegionId = filter.masterDataReducer.RegionDetails.RegionId;
      var CountryId = filter.masterDataReducer.CountryDetails.CountryId;
      var ProvinceId =
        filter.masterDataReducer.ProvinceDetails?.ProvinceId == undefined ||
        null
          ? 0
          : filter.masterDataReducer.ProvinceDetails?.ProvinceId;
      var ReportingStatusId =
        filter.masterDataReducer.ReportingStatusDetails.ReportingStatusId;
      var AuditStatusId =
        filter.masterDataReducer.AuditStatusDetails.AuditStatusId;
      var LoggedInUserEmail = filter.masterDataReducer.LoggedInUserEmail;

      const formData = new FormData();
      formData.append("Id", rowData.Id);
      formData.append("EventName", rowData.EventName);
      formData.append("ReportingPeriod", rowData.ReportingPeriod);
      formData.append("Region", RegionId);
      formData.append("Country", CountryId);
      formData.append("Province", ProvinceId);
      formData.append("CompanyCode", rowData.CompanyCode);
      formData.append("Scheme", rowData.Scheme);
      formData.append("OrgName", rowData.OrgName);
      formData.append("ReportingFrequency", rowData.ReportingFrequency);
      formData.append("ReportingDeadline", rowData.ReportingDeadline);
      formData.append("ReportingLOB", rowData.ReportingLOB);
      formData.append("MicrosoftETACompletion", rowData.MicrosoftETACompletion);
      formData.append("MicrosoftFinalDeadline", rowData.MicrosoftFinalDeadline);
      formData.append("ReportingStatus", ReportingStatusId);
      formData.append("Location", rowData.Location);
      formData.append("AuditSchedule", rowData.AuditSchedule);
      formData.append("AuditStatus", AuditStatusId);
      formData.append("Notes", rowData.Notes);
      formData.append("UpdatedBy", LoggedInUserEmail);
      const response = await axiosInstance.post(
        restApiEndpoints.baseUrl +
          restApiEndpoints.updateSingleCalendarAdminEndPoint,
        formData
      );
      if (response.status === 201) {
        dispatch(fetchAdmins());
        dispatch(SetNotificationMessage("Updated row successfully!"));
        dispatch(SetIsOpenSuccessMsgBar(true));
      } else {
        dispatch(
          SetErrorNotificationMessage(
            "Your request is not processed. Could you please try again later."
          )
        );
        dispatch(SetIsOpenErrorMsgBar(true));
      }
    } catch (err) {
      if (err.response.request.status === 409) {
        dispatch(
          SetErrorNotificationMessage(
            "Event with following combinations-" +
              rowData.Region +
              "," +
              rowData.Country +
              "," +
              rowData.Province +
              "," +
              rowData.Scheme +
              "," +
              rowData.LOB +
              " already exists!"
          )
        );
        dispatch(SetIsOpenErrorMsgBar(true));
      } else {
        dispatch(
          SetErrorNotificationMessage(
            "Your request is not processed. Could you please try again later."
          )
        );
        dispatch(SetIsOpenErrorMsgBar(true));
      }
    } finally {
      dispatch(SetCalendarAdminSpinner(false));
    }
  };
};

export const saveBulkCalendarAdmins = async (data) => {
    return async (dispatch) => {
        dispatch(SetCalendarAdminSpinner(true));
        try {
            var filter = store.getState();
            var LoggedInUserEmail = filter.masterDataReducer.LoggedInUserEmail
            const formData = new FormData();
            formData.append("formFile", data.target.files[0]);
            formData.append("fileName", data.target.files[0].name);
            formData.append("CreatedBy", LoggedInUserEmail);
            const response = await axiosInstance.post(restApiEndpoints.baseUrl + restApiEndpoints.postBulkCalendarAdminEndPoint, formData)
            if (response.status === 201) {
                dispatch(fetchAdmins());
                dispatch(SetNotificationMessage("Excel is imported!"));
                dispatch(SetIsOpenSuccessMsgBar(true));
                dispatch(ActionCreator.requestEvents());
            }
        } catch (ex) {
            if (ex.response.status == "400") {
                 dispatch(SetBulkValidationErrors(ex.response.data));
                 dispatch(SetIsOpenErrorsDialog(true));
            } else {
                dispatch(SetErrorNotificationMessage(
                    "Your request is not processed. Could you please try again later."
                ));
                dispatch(SetIsOpenErrorMsgBar(true));
            }
        }
        finally {
            dispatch(SetCalendarAdminSpinner(false));
        }
    }
}

export const fetchEditRowData = () => {
  return () => {
    var filter = store.getState();
    if (filter.dataCalendarAdmin.editRowData.ReportingDeadline != null || "")
      filter.dataCalendarAdmin.editRowData.ReportingDeadline = convertDate(
        filter.dataCalendarAdmin.editRowData.ReportingDeadline
      );
    if (
      filter.dataCalendarAdmin.editRowData.MicrosoftETACompletion != null ||
      ""
    )
      filter.dataCalendarAdmin.editRowData.MicrosoftETACompletion = convertDate(
        filter.dataCalendarAdmin.editRowData.MicrosoftETACompletion
      );
    if (
      filter.dataCalendarAdmin.editRowData.MicrosoftFinalDeadline != null ||
      ""
    )
      filter.dataCalendarAdmin.editRowData.MicrosoftFinalDeadline = convertDate(
        filter.dataCalendarAdmin.editRowData.MicrosoftFinalDeadline
      );
    if (filter.dataCalendarAdmin.editRowData.AuditSchedule != null || "")
      filter.dataCalendarAdmin.editRowData.AuditSchedule = convertDate(
        filter.dataCalendarAdmin.editRowData.AuditSchedule
      );
    return filter.dataCalendarAdmin.editRowData;
  };
};

export const FetchReportingYears = async (data) => {
  return async (dispatch) => {

    dispatch(SetCalendarAdminSpinner(true));
    try {
        const response = await axiosInstance.get(
            restApiEndpoints.baseUrl +
              restApiEndpoints.getReportingYears
          );
          if (response.status === 200) {
            dispatch(SetReportingYears(response.data))
          }
    }
    catch(err){

    }
    finally {
        dispatch(SetCalendarAdminSpinner(false));
      }
  };
};

export const SetCopyEvents = async (data) => {
    return async (dispatch) => {
        dispatch(SetCalendarAdminSpinner(true));
        try {
            const response = await axiosInstance.post(
                restApiEndpoints.baseUrl +
                  restApiEndpoints.copyBulkEvents,data
              );
              dispatch(SetCalendarAdminSpinner(false));
              if (response.status === 201) {
                dispatch(fetchAdmins());
                dispatch(SetIsOpenCopyDialog(false));
                dispatch(SetNotificationMessage('Copy bulk events is successful.'))
                dispatch(SetIsOpenSuccessMsgBar(true));
                dispatch(ActionCreator.requestEvents());
              }
        }
        catch(err){
            dispatch(SetIsOpenCopyDialog(false));
            dispatch(SetErrorNotificationMessage('Your request is not processed. Could you please try again later.'))
            dispatch(SetIsOpenErrorMsgBar(true));
        }
        finally {
            dispatch(SetCalendarAdminSpinner(false));
            
          }
      };
};
export const SetEmptyEditRowData = () => {
  return (dispatch) => {
    dispatch(ResetAddEditForm());
  };
};

function convertDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
