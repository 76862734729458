import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { PropertiesPlugin } from '@microsoft/applicationinsights-properties-js';
import { detect } from 'detect-browser';
let reactPlugin = null;
let appInsights = null;
let propertiesPlugin = null;
/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    
    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Function} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (instrumentationKey) => {
       
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided in ./TelemetryProvider.js')
        }

        reactPlugin = new ReactPlugin();
        propertiesPlugin = new PropertiesPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                 disableFetchTracking: true,
                extensions: [reactPlugin],
                enableAutoRouteTracking: true,
                extensionConfig: {
                    [reactPlugin.pluginIdentifier]: {
                      enableRequestTracking: true,
                    
                    },
                    [propertiesPlugin.identifier]: {
                        // Custom properties
                        cloudRoleName: 'auto',
                      },
                  },
            }
        });

        appInsights.loadAppInsights();
        
        const browser = detect();

        
        appInsights.addTelemetryInitializer(function (envelope) {
            envelope.data["ServiceOffering"] = "Supply Chain";
            envelope.data["ServiceLine"] = "Environmental compliance and Sustainability ";
            envelope.data["Service Name"] = "ECST Portal";
            envelope.data["ComponentName"] = "ECST Portal";
            envelope.data["EnvironmentName"] = process.env.REACT_APP_ENVIRONMENT;
            envelope.data["Browser"] = browser.name;
          
        });
    };

    return {reactPlugin, appInsights, initialize};
};

export const ai = createTelemetryService();
export const getAppInsights = () => { return appInsights; };