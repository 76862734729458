import { useState, useEffect } from "react";
import React from "react";
import {
  createScope,
  partnerCenterTheme,
  dialog,
  button,
  icon,
  textField,
  select,
  option,
  textArea,
  text
} from "@harmony/enablers/react";

const scope = createScope({
  reactInstance: React,
  theme: partnerCenterTheme,
});

const Dialog = scope.forReact(dialog);
const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const TextField = scope.forReact(textField);
const Select = scope.forReact(select);
const Option = scope.forReact(option);
const TextArea = scope.forReact(textArea);
const Text = scope.forReact(text);

const buttonContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  gap: "var(--he-spacing-x-small)",
  marginBottom: "var(--he-spacing-large)",
  justifyContent: "flex-end",
};


const AddEditDialog = (props) => {
  const regionRef = React.useRef(null);
  const countryRef = React.useRef(null);
  const schemeRef = React.useRef(null);
  const LOBRef = React.useRef(null);
  const userIdRef = React.useRef(null);
  const pwdRef = React.useRef(null);

  useEffect(() => {

    regionRef.current?.setCustomValidity("");
    countryRef.current?.setCustomValidity("");
    //schemeRef.current?.setCustomValidity("");
    // LOBRef.current?.setCustomValidity("");
    // userIdRef.current?.setCustomValidity("");
    // pwdRef.current?.setCustomValidity("");
  }, [props.isOpen]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.inEditMode) {
      props.onEdit();
    } else {
      props.onSave();
    }
  };

  return (
    <>
      <Dialog
        heading={props.inEditMode ? "Edit Row" : "Add New Row"}
        style={{ "--width": "400px" }}
        open={props.isOpen}
        onHeAfterHide={() => props.handleCloseDialog()}
      >
        <form onSubmit={handleSubmit}>
        <Text appearance="paragraph" style={{ color:"#e50000"}}>
        * Field is mandatory.
        </Text>
          <Select
            label="Region"
            autocomplete="both"
            value={props.data.Region}
            name="Region"
            onHeChange={props.onFormControlChange}
            ref={regionRef}
            required
         
          >
            {props.RegionList.map((region) => {
              return (
                <Option key={region.RegionId} data-regionid={region.RegionId}>
                  {region.RegionName}
                </Option>
              );
            })}
          </Select>
          <Select
            label="Country"
            autocomplete="both"
            value={props.data.Country}
            name="Country"
            onHeChange={props.onFormControlChange}
            required
            ref={countryRef}
            disabled={props.CountryList.length == 0 ? true : false}
          >
            {props.CountryList.map((country) => {
              return (
                <Option
                  key={country.CountryId}
                  data-countryid={country.CountryId}
                >
                  {country.CountryName}
                </Option>
              );
            })}
          </Select>
          <Select
            label="Province"
            autocomplete="both"
            value={props.data.Province}
            name="Province"
            onHeChange={props.onFormControlChange}
            disabled={props.ProvinceList.length == 0 ? true : false}
          >
            {props.ProvinceList.map((region) => {
              return (
                <Option key={region.ProvinceId}>{region.ProvinceName}</Option>
              );
            })}
          </Select>
          <TextField
            label="Scheme"
            value={props.data.Scheme}
            name="Scheme"
            onHeChange={props.onFormControlChange}
            ref={schemeRef}
            required
            maxlength = "35"
          />
          <TextField
            label="LOB"
            value={props.data.LOB}
            name="LOB"
            onHeChange={props.onFormControlChange}
            required
            ref={LOBRef}
            maxlength = "35"
          />
          <TextField
            label="UserId"
            value={props.data.UserId}
            name="UserId"
            onHeChange={props.onFormControlChange}
            required
            ref={userIdRef}
            maxlength = "35"
          />
          <TextField
            label="Password"
            type="password"
            toggle-password
            name="Password"
            value={props.data.Password}
            onHeChange={props.onFormControlChange}
            required
            ref={pwdRef}
            maxlength = "35"
          />
          <TextField
            label="Url"
            value={ props.data.Url}
            onHeChange={props.onFormControlChange}
            name="Url"
            maxlength = "100"
          ></TextField>
          <TextField
            label="Email"
            value={props.data.Email}
            name="Email"
            onHeChange={props.onFormControlChange}
            maxlength = "100"
            type="email"
          />

          <TextArea label="Notes" value={props.data.Notes}
            name="Notes"
            onHeChange={props.onFormControlChange}  maxlength = "200"/>
          <div slot="footer" style={{ marginTop: "20px" }}>
            <div style={buttonContainerStyle}>
              <Button appearance="primary" type="submit">
                Save
              </Button>

              <Button
                onClick={() => {
                  props.handleCloseDialog();
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default AddEditDialog;
