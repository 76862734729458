import React from "react";
import { createScope, progressRing } from "@harmony/enablers/react";

const scope = createScope({
  reactInstance: React,
});
const spinnerStyle = {
  position: "absolute",
  left: "50%",
  top: "50%",
};
const overlayDiv = {
  position: "fixed" ,
  width: "100%" ,
  height: "100%" ,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)" ,
  zIndex: 15 ,
  cursor: "pointer" ,
};
const ProgressRing = scope.forReact(progressRing);
export const Spinner = (props) => {
  return props.ShowSpinner ? (
    <div style={overlayDiv}>
      <ProgressRing indeterminate style={spinnerStyle}></ProgressRing>
    </div>
  ) : null;
};
