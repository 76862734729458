import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavItem } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import './NavMenu.css';
import { createScope, icon, tab } from '@harmony/enablers/react';
const scope = createScope({
    reactInstance: React
});
const Tab = scope.forReact(tab);
const Icon = scope.forReact(icon);

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light role="navigation">
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow" >
           <NavItem>
              <NavLink to="/"  style={({ isActive }) => ({color: isActive ? 'black' : 'black', textDecoration: 'none',fontWeight: isActive ? 'bold' : 'normal',margin: '10px' })}><Icon slot="start" name="home"></Icon> Home</NavLink>
              </NavItem>
              <NavItem>
              <NavLink tag={Link} to="/calendar" style={({ isActive }) => ({color: isActive ? 'black' : 'black', textDecoration: 'none',fontWeight: isActive ? 'bold' : 'normal',margin: '10px' })}><Icon slot="start" name="calendar"></Icon> Calendar</NavLink>
              </NavItem>
              <NavItem>
              <NavLink tag={Link} to="/credential" style={({ isActive }) => ({color: isActive ? 'black' : 'black', textDecoration: 'none',fontWeight: isActive ? 'bold' : 'normal',margin: '10px' })} title="credential"><Icon slot="start" name="passiveauthentication"></Icon> Credential</NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Navbar>
     
    );
  }
}
