import React from "react";
import { useMsal } from "@azure/msal-react";
import {
    createScope,
    button,
    partnerCenterTheme
  } from "@harmony/enablers/react";

  const scope = createScope({
    reactInstance: React,
    theme: partnerCenterTheme,
  });
  

  const Button = scope.forReact(button);
/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOut = () => {
    const { instance } = useMsal();
    
    const handleLogout = () => {
       
           instance.logoutRedirect({
                postLogoutRedirectUri: null,
            });
        
    }

    return (
        <Button variant="secondary" className="ml-auto" onClick={() => handleLogout()} style={{"marginLeft":"40px"}}>Sign out</Button>
    );
}