
export const getDefaultCalendarAdminFormData = function () {
    return {
        Id: "",
        EventName: "",
        ReportingPeriod: "",
        Region: "",
        RegionName:"",
        RegionId:0,
        Country: "",
        Province: "",
        CompanyCode: "",
        Scheme: "",
        OrgName: "",
        ReportingFrequency: "",
        ReportingDeadline: "",
        ReportingLOB: "",
        MicrosoftETACompletion: "",
        MicrosoftFinalDeadline: "",
        ReportingStatus: "",
        ReportingStatusId: 0,
        Location: "",
        AuditSchedule: "",
        AuditStatus: "",
        Notes: "",
    };
}