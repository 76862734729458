import React, { useEffect } from 'react';
import { createScope, partnerCenterTheme, tabs, tab, tabPanel, icon } from '@harmony/enablers/react';
import Admin from "../pages/calendarAdmin";
import Events from '../pages/event';
import { AuthGuard } from "../Authentication/AuthGuard";
import { AppRoles } from "../constants";
import {useCheckIfAuthorized , useGetLoggedInUserEmail} from '../Authentication/AuthUtil';

const scope = createScope({
  theme: partnerCenterTheme,
  reactInstance: React
});

const Tabs = scope.forReact(tabs);
const Tab = scope.forReact(tab);
const TabPanel = scope.forReact(tabPanel);
const Icon = scope.forReact(icon);

const Calendar = () => {
  const { checkAuth } = useCheckIfAuthorized();
  if (checkAuth([AppRoles.ECSTAdmin])) {
    return (
      <div b-mclwlhzjgy="true" className="navbar navbar-default navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border box-shadow mb-3" style={{  paddingLeft: "35px" }}>
        <Tabs size="large" style={{ width: "100%" }} onHeChange={(e)=>{
            
            window.dispatchEvent(new Event("resize"))
          }} >
          <Tab title='Event'><Icon name="Events" slot="start"></Icon>Events</Tab>
          <Tab title='Admin'><Icon name="Admin" slot="start"></Icon>Admin</Tab>
          <TabPanel><Events /></TabPanel>
          <TabPanel><Admin /></TabPanel>
        </Tabs>
      </div>
    );
  }
  else {
    return (
      <div b-mclwlhzjgy="true" className="navbar navbar-default navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border box-shadow mb-3" style={{ marginLeft: "30px", marginRight: "30px", paddingLeft: "35px" }}>
        <Tabs size="large" style={{ width: "100%" }}>
          <Tab><Icon name="Events" slot="start"></Icon>Events</Tab>
          <TabPanel><Events /></TabPanel>
        </Tabs>
      </div>
    );
  }
};
export default Calendar;