import { useMsal } from "@azure/msal-react";


const useCheckIfAuthorized = () => {
  const { instance } = useMsal();
 const checkAuth = (roles) => {
    
    
    const currentAccount = instance.getActiveAccount();

    if (currentAccount && currentAccount.idTokenClaims["roles"]) {
      let intersection = roles.filter((role) =>
        currentAccount.idTokenClaims["roles"].includes(role)
      );

      if (intersection.length > 0) {
       
        return true;
      }
     
    }
    return false;
  }
  return {checkAuth};
  };

  const useGetLoggedInUserEmail = ()=> {
    const { instance } = useMsal();
    const loggedInUserEmail = async(roles) => {
    const currentAccount = instance.getActiveAccount();
    return currentAccount.username;
    }
    return {loggedInUserEmail};
  }

  const useGetLoggedInUserName = ()=> {
    const { instance } = useMsal();
    const loggedInUserName = async(roles) => {
    const currentAccount = instance.getActiveAccount();
    return currentAccount.name;
    }
    return {loggedInUserName};
  }
  export  {useCheckIfAuthorized, useGetLoggedInUserEmail, useGetLoggedInUserName};

