import React, { Component } from "react";
import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Layout } from "./components/Layout";
import "./custom.css";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { AppRoles, AuthGuardType } from "./constants";
import { AuthGuard } from "./Authentication/AuthGuard";
import TelemetryProvider from "./Telemetry/TelemetryProvider";
import { getAppInsights } from "./Telemetry/TelemetryService";
export default class App extends Component {
  constructor(props) {
    super(props);
  }
  static displayName = App.name;

  render() {
    return (
      <TelemetryProvider
      instrumentationKey={process.env.REACT_APP_INSTRUMENTATIONKEY}
      after={() => {
        this.setState({ appInsights: getAppInsights() });
      }}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <div>
          <Layout>
            <Routes>
              {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return (
                <Route
                  key={index}
                  {...rest}
                  element={
                    <AuthGuard
                      roles={[
                        AppRoles.ECSTAdmin,
                        AppRoles.ECSTInternalUser,
                        AppRoles.ECSTExternalUser,
                      ]}
                      typeOfGuard={AuthGuardType.Route}
                    >
                      <main>
                      {element}
                      </main>
                     
                    </AuthGuard>
                  }
                />)
              })}
            </Routes>
          </Layout>
        </div>
        
      </MsalAuthenticationTemplate>
      </TelemetryProvider>
     
    );
  }
}