import React from "react";

const Footer = (props) => {
  const currentyear = new Date().getFullYear()
  return (
    <div className="navbar-fixed-bottom" role="contentinfo">
    <footer
      
      className="border-top" 
      role="contentinfo"
    >
      <p style={{ textAlign: "center" }}>
        © {currentyear} - Finance & Compliance -{" "}
        <a   href="https://go.microsoft.com/fwlink/?LinkId=518021">
          MS Data Privacy Notice
        </a>
      </p>
    </footer>
    </div>


  );
};

export default Footer;
