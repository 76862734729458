import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../styles/credential.css";
import "../styles/ecstCommon.css";
import AddEditDialog from "../components/Dialog";
import Moment from "moment";
import { getDefaultCredentialFormData } from "../models/CredentialFormData";
import { CredentialsApi } from "../adapters/credetialsIApi";
import { AuthGuard } from "../Authentication/AuthGuard";
import {
  createScope,
  button,
  icon,
  dropdown,
  menu,
  menuItem,
  searchBox,
  select,
  option,
  messageBar,
  text,
  dialog,
} from "@harmony/enablers/react";
import * as XLSX from "xlsx";
import axiosInstance from "../adapters/AxiosSetup";
import { AppRoles, AuthGuardType, restApiEndpoints } from "../constants";
import { MasterData } from "../models/MasterData";
import { Color } from "ag-grid-community";
import { Spinner } from "../components/Spinner";
import { useMsal } from "@azure/msal-react";
import { Alert } from "reactstrap";
import {
  useCheckIfAuthorized,
  useGetLoggedInUserEmail,
  useGetLoggedInUserName,
} from "../Authentication/AuthUtil";
import { getAppInsights } from "../Telemetry/TelemetryService";
const scope = createScope({
  reactInstance: React,
});

const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const Dropdown = scope.forReact(dropdown);
const Menu = scope.forReact(menu);
const MenuItem = scope.forReact(menuItem);
const SearchBox = scope.forReact(searchBox);
const Select = scope.forReact(select);
const Option = scope.forReact(option);
const MessageBar = scope.forReact(messageBar);
const Text = scope.forReact(text);
const Dialog = scope.forReact(dialog);

const Credential = () => {
  const [gridApi, setGridApi] = useState(null);

  const [tableData, setTableData] = useState(null);
  const [credentailRowData, setCredentailRowData] = useState(
    getDefaultCredentialFormData()
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEmailDialog, setIsOpenEmailDialog] = useState(false);
  const [isOpenSuccessMsgBar, setIsOpenSuccessMsgBar] = useState(false);
  const [isOpenErrorMsgBar, setIsOpenErrorMsgBar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState();
  const [inEditMode, setinEditMode] = useState(false);
  const [colDefs, setColDefs] = useState(null);
  const [data, setData] = useState(null);
  const grid = useRef();
  const [RegionList, SetRegionList] = useState([]);
  const [CountryList, SetCountryList] = useState([]);
  const [ProvinceList, SetProvinceList] = useState([]);
  const [masterDataList, SetMasterData] = useState(new MasterData());
  const [showSpinner, SetShowSpinner] = useState(false);
  const [EmailDialogBody, SetEmailDialogBody] = useState("");
  const credentialsApi = new CredentialsApi();
  const [ErrorNotificationMessage, SetErrorNotificationMessage] = useState();
  const [isOpenErrorsDialog, SetisOpenErrorsDialog] = useState(false);
  const [validationErrors, SetValidationErrors] = useState([]);
  const [pageSize, SetPageSize] = useState("20");
  const { checkAuth } = useCheckIfAuthorized();
  const { loggedInUserEmail } = useGetLoggedInUserEmail();
  const { loggedInUserName } = useGetLoggedInUserName();
  const GetPasswordRef = React.useRef(null);
  const { appInsights } = getAppInsights();
  const ShowOrHideEdit = () => {
    return !checkAuth([AppRoles.ECSTAdmin]);
    // return true;
  };
  const OnClickGetPassword = async (rowData) => {
    var loggedInEmail = await loggedInUserEmail();
    if (checkAuth([AppRoles.ECSTAdmin, AppRoles.ECSTInternalUser])) {
      var response = await GetPasswordInEmail(rowData, loggedInEmail);
    } else {
      var response = await SendPasswordToAdmins(rowData, loggedInEmail);
    }
  };
  const columns = [
    {
      headerName: "Region",
      field: "Region",
      tooltipField: "Region",
    },
    {
      headerName: "Country",
      field: "Country",
      tooltipField: "Country",
    },
    {
      headerName: "Province",
      field: "Province",
      tooltipField: "Province",
    },
    {
      headerName: "Scheme",
      field: "Scheme",
      tooltipField: "Scheme",
    },
    {
      headerName: "LOB",
      field: "LOB",
      tooltipField: "LOB",
    },
    {
      headerName: "UserId",
      field: "UserId",
      tooltipField: "UserId",
    },
    {
      headerName: "Password",
      field: "Id",
      cellRenderer: function (params) {
        return (
          <Button
            appearance="link"
            onClick={() => OnClickGetPassword(params.data)}
            title="Get Password"
          >
            Get Password
          </Button>
        );
      },
    },
    {
      headerName: "URL",
      field: "Url",
      cellRenderer: function (params) {
        return params.value !== "" && params.value !== null ? (
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              openExternalUrl(params.value);
              event.preventDefault();
            }}
            title="Country portal Link"
            aria-label="Country portal Link"
            class="url_link"
          >
            Country portal Link
          </a>
        ) : null;
      },
      tooltipField: "Url",
    },
    {
      headerName: "Email",
      field: "Email",
      tooltipField: "Email",
    },
    {
      headerName: "Notes",
      field: "Notes",
      tooltipField: "Notes",
    },
    {
      headerName: "Updated By",
      field: "UpdatedBy",
      tooltipField: "UpdatedBy",
    },
    {
      headerName: "Updated On",
      field: "UpdatedOn",
      tooltipField: "UpdatedOn",
    },
    {
      headerName: "Edit",
      field: "Id",
      cellRenderer: (params) => (
        <div>
          <Button
            onClick={() => editRowHandler(params.data)}
            title="Edit"
            appearance="command"
            aria-describedby="Edit"
          >
            <Icon slot="start" name="edit" label="Edit"></Icon>
          </Button>
        </div>
      ),
      hide: ShowOrHideEdit(),
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    headerClass: "header-style",
    cellStyle: { textAlign: "center" },
    suppressKeyboardEvent: (params) => {
      const e = params.event;
      if (e.code == "Tab" || e.key == "Tab") {
        //get focusable children of parent cell
        let focusableChildrenOfParent = e.srcElement
          .closest(".ag-cell")
          .querySelectorAll(
            'he-button, [href], :not(.ag-hidden) > input, select, textarea, [tabindex]:not([tabindex="-1"])'
          );
        const { rowIndex, column } = params.api.getFocusedCell();

        if (
          rowIndex === pageSize - 1 &&
          column.colDef.field ===
            params.api.getColumnDefs()[params.api.getColumnDefs().length - 1]
              .field &&
          e.srcElement
            .closest(".ag-cell")
            .querySelectorAll('he-button[title="Edit"]').length > 0 &&
          e.shiftKey == false
        ) {
          // last row and column of a page
          return true;
        } else if (
          focusableChildrenOfParent.length == 0 ||
          (e.shiftKey == false &&
            e.srcElement ==
              focusableChildrenOfParent[
                focusableChildrenOfParent.length - 1
              ]) ||
          (e.shiftKey == true &&
            e.srcElement == focusableChildrenOfParent[0]) ||
          (e.shiftKey == true && e.srcElement.classList.contains("ag-cell"))
        ) {
          return false; //do not suppress
        }

        return true; //suppress
      }
      return false; //do not suppress by default
    },
  };

  useEffect(() => {
    getCredentialTableData();
    getMasterData();
  }, []);
  const getCredentialTableData = async () => {
    try {
      const response = await credentialsApi.GetCredentialTableData();
      setTableData(response.data);
    } catch (err) {
      appInsights.trackException({ exception: err });
      SetErrorNotificationMessage(
        "Your request is not processed. Could you please try again later."
      );
      setIsOpenErrorMsgBar(true);
    }
  };

  const getMasterData = async () => {
    const res = await credentialsApi.FetchMasterData();
    SetMasterData(res.data);
    SetRegionList(res.data.Regions);
  };

  const GetPasswordInEdit = async (key) => {
    try {
      const formData = new FormData();
      formData.append("CorrelationId", key);
      const response = await credentialsApi.FetchPasswordInEdit(formData);
      return response.data;
    } catch (err) {
      appInsights.trackException({ exception: err });
      SetErrorNotificationMessage(
        "Your request is not processed. Could you please try again later."
      );
      setIsOpenErrorMsgBar(true);
    }
  };

  const GetPasswordInEmail = async (rowData, loggedinUserEmail) => {
    SetShowSpinner(true);
    try {
      const formData = new FormData();

      formData.append("CredCorrelationId", rowData.CorrelationId);
      formData.append("ToEmail", loggedinUserEmail);
      formData.append("UserId", rowData.UserId);
      formData.append("Url", rowData.Url);
      formData.append("Country", rowData.Country);
      const response = await credentialsApi.FetchPasswordInEmail(formData);
      SetEmailDialogBody("Password has been sent to your mailbox");
      handleOpenEmailDialog();
      return response.data;
    } catch (err) {
      appInsights.trackException({ exception: err });
      SetErrorNotificationMessage(
        "Your request is not processed. Could you please try again later."
      );
      setIsOpenErrorMsgBar(true);
    } finally {
      SetShowSpinner(false);
    }
  };
  const SendPasswordToAdmins = async (rowData, loggedinUserEmail) => {
    SetShowSpinner(true);
    try {
      const formData = new FormData();
      var loggedInEmail = await loggedInUserEmail();
      var loggedInName = await loggedInUserName();
      formData.append("CredCorrelationId", rowData.CorrelationId);
      formData.append("ToEmail", "");
      formData.append("UserId", rowData.UserId);
      formData.append("Url", rowData.Url);
      formData.append("Country", rowData.Country);
      formData.append("RequestedByEmail", loggedInEmail);
      formData.append("RequestedByName", loggedInName);

      const response = await credentialsApi.SendPasswordToAdmins(formData);
      SetEmailDialogBody(
        "Password has been sent to your Admin's mailbox. Please contact Admin."
      );
      handleOpenEmailDialog();
      return response.data;
    } catch (err) {
      appInsights.trackException({ exception: err });
      SetErrorNotificationMessage(
        "Your request is not processed. Could you please try again later."
      );
      setIsOpenErrorMsgBar(true);
    } finally {
      SetShowSpinner(false);
    }
  };
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
  };

  async function getCredentialData() {
    try {
      getCredentialTableData();
    } catch (err) {
      appInsights.trackException({ exception: err });
      SetErrorNotificationMessage(
        "Your request is not processed. Could you please try again later."
      );
      setIsOpenErrorMsgBar(true);
    }
  }
  const importExcel = async (e) => {
    SetShowSpinner(true);

    try {
      const formData = new FormData();
      formData.append("formFile", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("CreatedBy", await loggedInUserEmail());
      const response = await credentialsApi.PostBulkCredentialData(formData);
      if (response.status === 201) {
        getCredentialData();
        setNotificationMessage("Excel is imported!");
        setIsOpenSuccessMsgBar(true);
      }
    } catch (ex) {
      appInsights.trackException({ exception: ex });
      if (ex.response.status == "400") {
        SetValidationErrors(ex.response.data);
        SetisOpenErrorsDialog(true);
      } else {
        SetErrorNotificationMessage(
          "Your request is not processed. Could you please try again later."
        );
        setIsOpenErrorMsgBar(true);
      }
    } finally {
      SetShowSpinner(false);
      hiddenFileInput.current.value = "";
    }
  };

  const getSchemaErrors = (data) => {
    return (
      <div>
        <p>
          Please correct the below following errors and reupload the template
          again
        </p>
        <table className="table">
          <thead>
            <tr>
              <th>Row Number</th>
              <th>Error Message</th>
            </tr>
          </thead>

          <tbody>
            {data.map((row) => {
              return (
                <tr>
                  <td>{row.Path}</td>
                  <td>{row.Message}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const openExternalUrl = (url) => {
    var externalUrl;
    if (url.includes("https://") || url.includes("http://")) {
      externalUrl = url;
    } else {
      externalUrl = "//" + url;
    }
    var win = window.open(externalUrl, "_blank");
    win.focus();
  };
  const AddRow = async () => {
    handleCloseDialog();
    SetShowSpinner(true);

    try {
      const formData = new FormData();
      var regionId = RegionList.filter(
        (x) => x.RegionName == credentailRowData.Region
      )[0].RegionId;
      var countryId = CountryList.filter(
        (x) => x.CountryName == credentailRowData.Country
      )[0].CountryId;
      var provinceId =
        ProvinceList.filter((x) => x.ProvinceName == credentailRowData.Province)
          .length > 0
          ? ProvinceList.filter(
              (x) => x.ProvinceName == credentailRowData.Province
            )[0].ProvinceId
          : "0";
      formData.append("Region", regionId);
      formData.append("Country", countryId);
      formData.append("Province", provinceId);
      formData.append("Scheme", credentailRowData.Scheme);
      formData.append("LOB", credentailRowData.LOB);
      formData.append("UserId", credentailRowData.UserId);
      formData.append("Url", credentailRowData.Url);
      formData.append("Password", credentailRowData.Password);
      formData.append("Email", credentailRowData.Email);
      formData.append("Notes", credentailRowData.Notes);
      formData.append("CreatedBy", await loggedInUserEmail());
      formData.append("UpdatedBy", await loggedInUserEmail());

      const response = await credentialsApi.PostSingleCredentialData(formData);

      if (response.status === 201) {
        getCredentialData();
        setNotificationMessage("Added row successfully!");
        setIsOpenSuccessMsgBar(true);
      } else {
        SetErrorNotificationMessage(
          "Your request is not processed. Could you please try again later."
        );
        setIsOpenErrorMsgBar(true);
      }
    } catch (err) {
      appInsights.trackException({ exception: err });
      if (err.response.request.status === 409) {
        SetErrorNotificationMessage(
          "Credentials with following combinations -" +
            credentailRowData.Region +
            "," +
            credentailRowData.Country +
            "," +
            credentailRowData.Province +
            "," +
            credentailRowData.Scheme +
            "," +
            credentailRowData.LOB +
            " already exists!"
        );
        setIsOpenErrorMsgBar(true);
      } else {
        SetErrorNotificationMessage(
          "Your request is not processed. Could you please try again later."
        );
        setIsOpenErrorMsgBar(true);
      }
    } finally {
      SetShowSpinner(false);
    }
  };
  const UpdateRow = async () => {
    handleCloseDialog();
    SetShowSpinner(true);
    try {
      const formData = new FormData();
      var regionId = RegionList.filter(
        (x) => x.RegionName == credentailRowData.Region
      )[0].RegionId;
      var countryId = CountryList.filter(
        (x) => x.CountryName == credentailRowData.Country
      )[0].CountryId;
      var provinceId =
        ProvinceList.filter((x) => x.ProvinceName == credentailRowData.Province)
          .length > 0
          ? ProvinceList.filter(
              (x) => x.ProvinceName == credentailRowData.Province
            )[0].ProvinceId
          : "";
      formData.append("CorrelationId", credentailRowData.CorrelationId);
      formData.append("Region", regionId);
      formData.append("Country", countryId);
      formData.append("Province", provinceId);
      formData.append("Scheme", credentailRowData.Scheme);
      formData.append("LOB", credentailRowData.LOB);
      formData.append("UserId", credentailRowData.UserId);
      formData.append("Url", credentailRowData.Url);
      formData.append("Password", credentailRowData.Password);
      formData.append("Email", credentailRowData.Email);
      formData.append("Notes", credentailRowData.Notes);
      formData.append("UpdatedBy", await loggedInUserEmail());
      const response = await credentialsApi.UpdateCredentialData(formData);

      if (response.status === 201) {
        getCredentialData();
        setNotificationMessage("Updated row successfully!");
        setIsOpenSuccessMsgBar(true);
      } else {
        SetErrorNotificationMessage(
          "Your request is not processed. Could you please try again later."
        );
        setIsOpenErrorMsgBar(true);
      }
    } catch (err) {
      appInsights.trackException({ exception: err });
      if (err.response.request.status === 409) {
        SetErrorNotificationMessage(
          "Credentials with following combinations-" +
            credentailRowData.Region +
            "," +
            credentailRowData.Country +
            "," +
            credentailRowData.Province +
            "," +
            credentailRowData.Scheme +
            "," +
            credentailRowData.LOB +
            " already exists!"
        );
        setIsOpenErrorMsgBar(true);
      } else {
        SetErrorNotificationMessage(
          "Your request is not processed. Could you please try again later."
        );
        setIsOpenErrorMsgBar(true);
      }
    } finally {
      SetShowSpinner(false);
    }
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onExportClick = () => {
    var excelParams = {
      columnKeys: [
        "Region",
        "Country",
        "Province",
        "Scheme",
        "LOB",
        "UserId",
        "Url",
        "Email",
        "Notes",
      ],
      allColumns: false,
      fileName: "ECST_" + Moment().format("DDMMYY") + ".csv",
      skipHeader: false,
    };
    gridApi.exportDataAsCsv(excelParams);
  };
  const onDownloadClick = async () => {
    SetShowSpinner(true);
    try {
      const response = await credentialsApi.DownloadTemplate();
      const url = window.URL.createObjectURL(new Blob([response.data]), {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ECST-Template.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (ex) {
      appInsights.trackException({ exception: ex });
      SetErrorNotificationMessage(
        "Your request is not processed. Could you please try again later."
      );
      setIsOpenErrorMsgBar(true);
    } finally {
      SetShowSpinner(false);
    }
  };

  const copyTable = () => {
    gridApi.selectAll();
    gridApi.copySelectedRowsToClipboard();
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };
  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleOpenEmailDialog = () => {
    setIsOpenEmailDialog(true);
  };
  const handleCloseEmailDialog = () => {
    setIsOpenEmailDialog(false);
  };
  const handleCloseErrorDialog = () => {
    SetisOpenErrorsDialog(false);
  };

  const addSingleRowHandler = () => {
    setCredentailRowData(getDefaultCredentialFormData());
    setinEditMode(false);
    handleOpenDialog();
  };
  const editRowHandler = async (rowData) => {
    SetShowSpinner(true);
    try {
      let secret = await GetPasswordInEdit(rowData.CorrelationId);
      rowData.Password = secret;

      setCredentailRowData(rowData);
      let regionName = rowData.Region;
      let seletedRegionId = masterDataList.Regions.filter(
        (x) => x.RegionName == regionName
      )[0].RegionId;
      const countries = masterDataList.Countries.filter(
        (x) => x.RegionId == seletedRegionId
      );
      SetCountryList(countries);
      let CountryName = rowData.Country;
      var countryId = masterDataList.Countries.filter(
        (x) => x.CountryName == CountryName
      )[0].CountryId;
      const provinces = masterDataList.Provinces.filter(
        (x) => x.CountryId == countryId
      );
      SetProvinceList(provinces);
      setinEditMode(true);
      SetShowSpinner(false);
      handleOpenDialog();
    } catch (err) {
      appInsights.trackException({ exception: err });
      SetShowSpinner(false);
    }
  };
  const onPaginationChange = (pageSize) => {
    gridApi.paginationSetPageSize(pageSize);
    SetPageSize(pageSize);
  };
  const onFormControlChange = (e) => {
    const { name, value } = e.target;

    if (name == "Region") {
      if (typeof e.target.selectedOption !== "undefined") {
        var regionId = e.target.selectedOption.getAttribute("data-regionid");
        var countries = masterDataList.Countries.filter(
          (x) => x.RegionId == regionId
        );
        SetCountryList(countries);
      }
    }
    if (name == "Country") {
      if (masterDataList.Provinces.length > 0) {
        if (typeof e.target.selectedOption !== "undefined") {
          var countryId =
            e.target.selectedOption.getAttribute("data-countryid");
          var provinces = masterDataList.Provinces.filter(
            (x) => x.CountryId == countryId
          );
          SetProvinceList(provinces);
        }
      }
    }
    setCredentailRowData((credentailRowData) => ({
      ...credentailRowData,
      [name]: value,
    }));
  };

  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };

  const overlay = () => {
    gridApi.showLoadingOverlay();
  };

  return (
    <div style={{ height: "100%" }}>
      <div id="navigation-announcer" role="alert" aria-live="polite">
        Navigated to Credentials Page
      </div>
      <h1 tabIndex="-1">Credential</h1>
      <div className="buttonContainerStyle">
        <Button
          onClick={() => onExportClick()}
          title="Export to CSV"
          appearance="command"
        >
          <Icon slot="start" name="excellogoinverse"></Icon>Export
        </Button>
        {isOpen == true ? (
          <AddEditDialog
            data={credentailRowData}
            onSave={AddRow}
            onEdit={UpdateRow}
            handleOpenDialog={handleOpenDialog}
            handleCloseDialog={handleCloseDialog}
            isOpen={isOpen}
            onFormControlChange={onFormControlChange}
            inEditMode={inEditMode}
            RegionList={RegionList}
            CountryList={CountryList}
            ProvinceList={ProvinceList}
            masterDataList={masterDataList}
            SetCountryList={SetCountryList}
            setCredentailRowData={setCredentailRowData}
          ></AddEditDialog>
        ) : null}

        <AuthGuard
          roles={[AppRoles.ECSTAdmin]}
          typeOfGuard={AuthGuardType.Control}
        >
          <Button
            onClick={() => addSingleRowHandler()}
            appearance="command"
            title="Add single new row"
          >
            <Icon slot="start" name="add"></Icon>Add Single Row
          </Button>
          <Button appearance="command" onClick={handleClick}>
            Upload Bulk Credentials<Icon slot="start" name="upload"></Icon>{" "}
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onInput={importExcel}
          />

          <Button
            title="Download template"
            onClick={() => onDownloadClick()}
            appearance="command"
          >
            <Icon slot="start" name="download"></Icon>Download Template
          </Button>
        </AuthGuard>

        <Text appearance="paragraph" style={{ paddingTop: "6px" }}>
          Page Size
        </Text>
        <Select
          value={pageSize}
          onHeChange={(e) => onPaginationChange(e.target.value)}
          style={{ width: "95px", paddingLeft: "10px" }}
          label="Page Size"
          hideLabel="true"
        >
          <Icon slot="start" name="filter" className="pageSizeStyle"></Icon>
          <Option value="5">5</Option>
          <Option value="10">10</Option>
          <Option value="15">15</Option>
          <Option value="20">20</Option>
        </Select>
        <SearchBox
          style={{ width: "20%", paddingLeft: 0, marginRight: 0 }}
          onHeChange={onFilterTextChange}
          className="container"
        />
      </div>
      <MessageBar
        appearance="success"
        open={isOpenSuccessMsgBar}
        onHeAfterHide={() => setIsOpenSuccessMsgBar(false)}
        style={{ marginBottom: "1rem" }}
      >
        <div role="alert" aria-live="assertive">
          {notificationMessage}
        </div>
      </MessageBar>
      <MessageBar
        appearance="error"
        open={isOpenErrorMsgBar}
        onHeAfterHide={() => setIsOpenErrorMsgBar(false)}
      >
        <div role="alert" aria-live="assertive">
          {ErrorNotificationMessage}
        </div>
      </MessageBar>
      <Dialog
        heading="Password Sent"
        style={{ "--width": "350px" }}
        open={isOpenEmailDialog}
        onHeAfterHide={() => handleCloseEmailDialog()}
      >
        <p>{EmailDialogBody}</p>

        <Button
          slot="footer"
          appearance="primary"
          onClick={() => handleCloseEmailDialog()}
        >
          Ok
        </Button>
      </Dialog>

      <Dialog
        heading="Validation Errors!!"
        style={{ "--width": "500px" }}
        open={isOpenErrorsDialog}
        onHeAfterHide={() => handleCloseErrorDialog()}
      >
        {getSchemaErrors(validationErrors)}
        <Button
          slot="footer"
          appearance="primary"
          onClick={() => handleCloseErrorDialog()}
          style={{ backgroundColor: "#00659E" }}
        >
          Ok
        </Button>
      </Dialog>

      <Spinner ShowSpinner={showSpinner}></Spinner>
      <div className="ag-theme-alpine" aria-label="Credentials Grid">
        <div
          style={{
            width: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            borderRadius: 90,
            marginTop: 20,
            marginBottom: 20,
            paddingBottom: 50
          }}
          aria-label="Credentials Grid"
        >
          <AgGridReact
            rowData={tableData}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            enableBrowserTooltips={true}
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={20}
            suppressScrollOnNewData={true}
            ref={grid}
            domLayout="autoHeight"
            ensureDomOrder={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Credential;
