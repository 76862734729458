import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import { createScope, icon } from "@harmony/enablers/react";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignOut } from "./SignOut";
import { Collapse, Navbar, NavbarToggler, NavItem } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import './NavMenu.css';
const scope = createScope({
  reactInstance: React,
});

const Icon = scope.forReact(icon);

const heading = {
  backgroundColor: "#3276b1",
  borderColor: "#3276b1",
};

const Header = (props) => {
  const { instance } = useMsal();
  const [userName, setUserName] = useState("");
  const [collapsed, setCollapsedState] = useState("");
  const isAuthenticated = useIsAuthenticated();
  
  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      setUserName(currentAccount.name);
    }
  }, [instance]);

  const toggleNavbar = () => {
    setCollapsedState(!collapsed)
    }

  return (
    <header role="banner">
     
  

      <nav
        b-mclwlhzjgy
        style={heading}
        className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow mb-3"
        role="navigation"
      >
        <div b-mclwlhzjgy className="container-fluid">
          <img
            src={logo}
            alt="Microsoft Logo"
            style={{ width: 160, height: 65 }}
            role="img"
          />

          <a style={{ color: "white" }} className="navbar-brand" href="/">
            ECST
          </a>

          <div
            b-mclwlhzjgy
            className="navbar-collapse collapse d-sm-inline-flex justify-content-end"
          ></div>
          <div style={{ color: "white" }}>
          
            Hello , {userName} !!
            {isAuthenticated ? <SignOut /> : null}
          </div>
        </div>
      </nav> 
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light role="navigation">
          <NavbarToggler className="mr-2" />
          <Collapse className="d-sm-inline-flex" isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow" >
           <NavItem>
              <NavLink to="/"  style={({ isActive }) => ({color: isActive ? 'black' : 'black', textDecoration: 'none',fontWeight: isActive ? 'bold' : 'normal',margin: '10px' })}><Icon slot="start" name="home"></Icon> Home</NavLink>
              </NavItem>
              <NavItem>
              <NavLink tag={Link} to="/calendar" style={({ isActive }) => ({color: isActive ? 'black' : 'black', textDecoration: 'none',fontWeight: isActive ? 'bold' : 'normal',margin: '10px' })}><Icon slot="start" name="calendar"></Icon> Calendar</NavLink>
              </NavItem>
              <NavItem>
              <NavLink tag={Link} to="/credential" style={({ isActive }) => ({color: isActive ? 'black' : 'black', textDecoration: 'none',fontWeight: isActive ? 'bold' : 'normal',margin: '10px' })} title="credential"><Icon slot="start" name="passiveauthentication"></Icon> Credential</NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Navbar> 
      
    </header>
  );
};

export default Header;
