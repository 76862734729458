import React, { Component } from "react";
import axiosInstance from './AxiosSetup'
import { restApiEndpoints } from "../constants";


export class CredentialsApi {
  CredentialsApi() {}
  async FetchMasterData() {
    let response ;
    try {
       response = await axiosInstance.get(
        restApiEndpoints.baseUrl + restApiEndpoints.getMasterData
      );     
    } catch (ex) {
      throw ex;
    }
    return response;
  }

  async PostBulkCredentialData(formData) {
    let response ;
    try {
        response = await axiosInstance.post(
        restApiEndpoints.baseUrl + restApiEndpoints.postBulkCredEndPoint,
        formData
      );      
    } catch (ex) {
      throw ex;
    }
    return response;
  }

  async PostSingleCredentialData(formData) {
    let response ;
    try {
        response = await axiosInstance.post(
        restApiEndpoints.baseUrl + restApiEndpoints.postSingleCredEndPoint,
        formData
      );      
    } catch (ex) {
      throw ex;
    }
    return response;
  }

  async UpdateCredentialData(formData) {
    let response;
    try {
        response = await axiosInstance.post(
        restApiEndpoints.baseUrl + restApiEndpoints.updateCredEndPoint,
        formData
      );      
    } catch (ex) {
      throw ex;
    }
    return response;
  }

  async FetchPasswordInEdit(formData) {
    let response;
    try {
        response = await axiosInstance.post(
        restApiEndpoints.baseUrl + restApiEndpoints.getPasswordInEdit,
        formData
      );      
    } catch (ex) {
      throw ex;
    }
    return response;
  }

  async FetchPasswordInEmail(formData) {
    let response;
    try {
        response = await axiosInstance.post(
        restApiEndpoints.baseUrl + restApiEndpoints.getPasswordInEmail,
        formData
      );      
    } catch (ex) {
      throw ex;
    }
    return response;
  }
  async SendPasswordToAdmins(formData) {
    let response;
    try {
        response = await axiosInstance.post(
        restApiEndpoints.baseUrl + restApiEndpoints.sendPasswordToAdmins,
        formData
      );      
    } catch (ex) {
      throw ex;
    }
    return response;
  }
  
  async GetCredentialData() {
    let response;
    try {
        response = await axiosInstance.get(
        restApiEndpoints.baseUrl + restApiEndpoints.getCredEndPoint
      );        
    } catch (ex) {
      throw ex;
    }
    return response;
  }

  async GetCredentialTableData() {
    let response;
    try {
      response = await axiosInstance.get(restApiEndpoints.baseUrl + restApiEndpoints.getCredEndPoint);
       
    } catch (ex) {
      throw ex;
    }
    return response;
  }

  async DownloadTemplate() {
    let response;
    try {
        response = await axiosInstance
        .get(
          restApiEndpoints.baseUrl + restApiEndpoints.downloadTemplateEndpoint,
          { responseType: 'arraybuffer' }
        )
    } catch (ex) {
      throw ex;
    }
    return response;
  }

  async DownloadCalendarAdminTemplate() {
    let response;
    try {
        response = await axiosInstance
        .get(
          restApiEndpoints.baseUrl + restApiEndpoints.downloadCalendarAdminTemplateEndpoint,
          { responseType: 'arraybuffer' }
        )
    } catch (ex) {
      throw ex;      
    }
    console.log(response)
    return response;
  }

  
}
