//UAT Constants
export const restApiEndpoints = {
  baseUrl: process.env.REACT_APP_API_URL,
  APIScope:process.env.REACT_APP_API_SCOPE,
  getCredEndPoint: "ecstapi/GetCredential",
  postBulkCredEndPoint: "ecstapi/SaveBulkCredentialData",
  postSingleCredEndPoint: "ecstapi/SaveSingleCredentialData",
  updateCredEndPoint: "ecstapi/UpdateCredentialData",
  getPasswordInEdit: "ecstapi/GetPasswordInEdit",
  getPasswordInEmail: "ecstapi/SendPasswordEmail",
  sendPasswordToAdmins: "ecstapi/SendPasswordToAdmins",
  getMasterData: "ecstapi/GetMasterData",
  downloadTemplateEndpoint: "ecstapi/DownloadFile",
  downloadCalendarAdminTemplateEndpoint: "ecstapi/DownloadCalendarAdminFile",
  getAdminEndPoint: "ecstapi/GetAdmin",
  getEvents: "ecstapi/getevents",
  postSingleCalendarAdminEndPoint: "ecstapi/SaveSingleCalendarAdminData",
  updateSingleCalendarAdminEndPoint: "ecstapi/UpdateSingleCalendarAdminEndPoint",
  postBulkCalendarAdminEndPoint: "ecstapi/SaveBulkCalendarAdminEndPoint",
  getReportingYears : "ecstapi/GetReportingYears",
  copyBulkEvents : "ecstapi/CopyBulkEvents"
};

export const AppRoles = {
  ECSTAdmin: "Ecst.Admin",
  ECSTInternalUser: "Ecst.InternalUser",
  ECSTExternalUser: "Ecst.ExternalUser",
};
export const AuthGuardType = {
  Route: "Route",
  Control: "Control",
};
