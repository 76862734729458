import React, { Component } from "react";
import axiosInstance from './AxiosSetup'
import { restApiEndpoints } from "../constants";


export class CalendarAdminsApi {
  CalendarAdminsApi() {}

  async PostSingleCalendarAdminData(formData) {
    let response ;
    try {
        response = await axiosInstance.post(
        restApiEndpoints.baseUrl + restApiEndpoints.postSingleCalendarAdminEndPoint,
        formData
      );      
    } catch (ex) {
      throw ex;
    }
    return response;
  }

  async UpdateCalendarAdminData(formData) {
    let response;
    try {
        response = await axiosInstance.post(
        restApiEndpoints.baseUrl + restApiEndpoints.updateSingleCalendarAdminEndPoint,
        formData
      );      
    } catch (ex) {
      throw ex;
    }
    return response;
  }
}
