import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { useSelector, useDispatch, connect } from "react-redux";
import { createSelector } from "reselect";

import ActionCreator from "../actions/EventAction";
import MasterDataActionCreator from "../actions/FetchMasterData";
import { fetchEvents } from "../actions/EventAction";
import { bindActionCreators } from "redux";
import { Spinner } from "../components/Spinner";
import {
  createScope,
  partnerCenterTheme,
  dialog,
  button,
  icon,
  textField,
  select,
  option,
  textArea,
  text,
} from "@harmony/enablers/react";
import { Label } from "reactstrap";

const scope = createScope({
  reactInstance: React,
  theme: partnerCenterTheme,
});

const Dialog = scope.forReact(dialog);
const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const TextField = scope.forReact(textField);
const Select = scope.forReact(select);
const Option = scope.forReact(option);
const TextArea = scope.forReact(textArea);
const Text = scope.forReact(text);

function mapStateToProps() {
  return (state) => {
    return {
      events: state.eventReducer,
      masterdata: state.masterDataReducer,
      isSpinner: state.eventReducer.isSpinner,
    };
  };
}

class Events extends React.Component {
  calendarRef = React.createRef();
 
  constructor(props) {
    
    super(props);

    this.state = {
      masterdata: this.props.fetchMasterData(),
      RegionName: "",
      RegionId: 0,
      ProvinceId: 0,
      StartDate: "",
      EndDate: "",
      CountryName: "",
      ProvinceName: "",
      CountryId: 0,
      Status: "",
      StatusId: "",
      ModalState: false,
      CLickedEvent: {},
      assertiveTitle: "",
    };
    window.dispatchEvent(new Event("resize"));
  }

  tabPanelStyle = {
    borderTop: "1px solid #ddd",
  };
  handleDates = (rangeInfo) => {
    this.setState({
      StartDate: rangeInfo.startStr.slice(0, 10),
      EndDate: rangeInfo.endStr.slice(0, 10),
    });
    this.props.setEventDates(
      rangeInfo.startStr.slice(0, 10),
      rangeInfo.endStr.slice(0, 10)
    );
    if(document.querySelector('.fc-timegrid-axis.fc-scrollgrid-shrink') != null){
      document.querySelector('.fc-timegrid-axis.fc-scrollgrid-shrink').setAttribute('aria-hidden', false)
    }
    window.dispatchEvent(new Event("resize"));
    this.props.requestEvents();
  };
  handleViewMount = (view) => {
    // Perform actions when a new view is rendered
   
    if(view.el.querySelector('.fc-timegrid-slots table') != null){
      view.el.querySelector('.fc-timegrid-slots table').setAttribute('aria-hidden', false)
    }
    if(view.el.querySelector('.fc-timegrid-axis.fc-scrollgrid-shrink') != null){
      view.el.querySelector('.fc-timegrid-axis.fc-scrollgrid-shrink').setAttribute('aria-hidden', false)

    }
  };
    
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.requestEvents();
  };
  resetFilter = (e) => {
    this.props.ResetFilter();
  };

  setIsOpen = (event, e) => {
    var eventdetails = this.props.events?.events?.filter(
      (x) => x.id == event?.event?.id
    )[0];

    var regionName = this.props.masterdata?.regions?.filter(
      (x) => x.RegionId == eventdetails?.regionId
    )[0]?.RegionName;

    var countryName = this.props.masterdata?.countries?.filter(
      (x) => x.CountryId == eventdetails?.countryId
    )[0]?.CountryName;

    var provinceName = this.props.masterdata?.provinces?.filter(
      (x) => x.ProvinceId == eventdetails?.provinceId
    )[0]?.ProvinceName;

    this.setState({
      ModalState: e,
      CLickedEvent: eventdetails,
      RegionName: regionName,
      CountryName: countryName,
      ProvinceName: provinceName,
    });
  };

  PrevBtnClick = (params) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.prev();
    this.state.assertiveTitle = calendarApi.getCurrentData().viewTitle;
  };
  NextBtnClick = (params) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.next();
    this.state.assertiveTitle = calendarApi.getCurrentData().viewTitle;
  };
  TodayBtnClick = (params) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.today();
    this.state.assertiveTitle = calendarApi.getCurrentData().viewTitle;
  };
  DayBtnClick = (params) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("timeGridDay");
    this.state.assertiveTitle = calendarApi.getCurrentData().viewTitle;
  };
  WeekBtnClick = (params) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("timeGridWeek");
    this.state.assertiveTitle = calendarApi.getCurrentData().viewTitle;
  };
  MonthBtnClick = (params) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("dayGridMonth");
    this.state.assertiveTitle = calendarApi.getCurrentData().viewTitle;
  };
 

    
  
 
  render() {
    return (
      <div style={this.tabPanelStyle}>
        <h1 tabIndex="-1">Events</h1>
        <div id="navigation-announcer" role="alert" aria-live="polite">
          Navigated to Events Page
        </div>

        <div id="navigation-announcer" role="alert" aria-live="assertive">
          {this.state.assertiveTitle}
        </div>
        <div
          className="buttonContainerStyle"
          style={{ border: "1px solid rgb(222, 226, 230)", margin: "5px" }}
        >
          <form onSubmit={this.handleSubmit} className="buttonContainerStyle">
            <Select
              label="Region"
              autocomplete="both"
              value={this.props.masterdata?.RegionDetails.RegionName}
              name="Region"
              appearance="command"
              style={{ margin: "18px" }}
              onHeChange={(e) => this.props.SetRegionName(e.target.value)}
              hideLabel="true"
              placeholder="Region"
            >
              <he-option key="">Select</he-option>
              {this.props.masterdata?.regions?.map((region) => {
                return (
                  <Option key={region.RegionId} data-regionid={region.RegionId}>
                    {region.RegionName}
                  </Option>
                );
              })}
            </Select>

            <Select
              label="Country"
              autocomplete="both"
              value={this.props.masterdata?.CountryDetails?.CountryName}
              name="Country"
              appearance="command"
              style={{ margin: "18px" }}
              onHeChange={(e) => this.props.SetCountryDetails(e.target.value)}
              hideLabel="true"
              placeholder="Country"
            >
              <he-option key="">Select</he-option>
              {this.props.masterdata?.loading ? (
                <h3>Loading Data</h3>
              ) : (
                this.props.masterdata?.countries?.map((country) => {
                  return (
                    <Option
                      key={country.CountryId}
                      data-countryid={country.CountryId}
                    >
                      {country.CountryName}
                    </Option>
                  );
                })
              )}
            </Select>
            <Select
              label="Province"
              autocomplete="both"
              value={this.props.masterdata?.ProvinceDetails?.ProvinceName}
              name="Province"
              appearance="command"
              style={{ margin: "18px" }}
              onHeChange={(e) => this.props.SetProvinceDetails(e.target.value)}
              hideLabel="true"
              placeholder="Province"
              disabled={this.props.masterdata?.provinces == 0 ? true : false}
            >
              <he-option key="">Select</he-option>
              {this.props.masterdata?.loading ? (
                <h3>Loading Data</h3>
              ) : (
                this.props.masterdata?.provinces?.map((province) => {
                  return (
                    <Option
                      key={province.ProvinceId}
                      data-provinceid={province.ProvinceId}
                    >
                      {province.ProvinceName}
                    </Option>
                  );
                })
              )}
            </Select>
            <Select
              label="Status"
              autocomplete="both"
              value={this.props.masterdata?.ReportingStatusDetails?.Status}
              name="Status"
              appearance="command"
              style={{ margin: "18px" }}
              onHeChange={(e) => this.props.SetStatusDetails(e.target.value)}
              hideLabel="true"
              placeholder="Status"
            >
              <he-option key="">Select</he-option>
              {this.props.masterdata?.loading ? (
                <h3>Loading Data</h3>
              ) : (
                this.props.masterdata?.reportingStatuses?.map((status) => {
                  return (
                    <Option
                      key={status.ReportingStatusId}
                      data-countryid={status.ReportingStatusId}
                    >
                      {status.Status}
                    </Option>
                  );
                })
              )}
            </Select>
            <div>
              <Button
                appearance="primary"
                type="submit"
                name="Search"
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  marginRight: "0px",
                }}
              >
                Search
                {/* <he-icon slot="end" name="searchart64"></he-icon> */}
              </Button>
              <Button
                appearance="primary"
                onClick={this.resetFilter}
                type="button"
                name="Reset"
                style={{ margin: "20px", marginTop: "21px" }}
              >
                Clear
              </Button>
            </div>
          </form>
        </div>
        {this.props.events.loading ? (
          <h3>Loading Data</h3>
        ) : this.props.events.error != "" ? (
          <h3>Some exception occurred</h3>
        ) : (
          <FullCalendar
            ref={this.calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            customButtons={{
              prevCustomBtn: {
                text: "Prev",
                click: this.PrevBtnClick,
                icon: "chevron-left",
              },
              nextCustomBtn: {
                text: "Next",
                click: this.NextBtnClick,
                icon: "chevron-right",
              },
              todayCustomBtn: {
                text: "today",
                click: this.TodayBtnClick,
              },
              timeGridDayBtn: {
                text: "day",
                click: this.DayBtnClick,
              },
              timeGridWeekBtn: {
                text: "week",
                click: this.WeekBtnClick,
              },
              dayGridMonthBtn: {
                text: "month",
                click: this.MonthBtnClick,
              },
            }}
            headerToolbar={{
              left: "prevCustomBtn,nextCustomBtn todayCustomBtn",
              center: "title",
              right: "timeGridDayBtn,timeGridWeekBtn,dayGridMonthBtn",
            }}
            initialView="dayGridMonth"
            weekends={true}
            events={this.props.events}
            datesSet={this.handleDates}
            handleWindowResize={true}
            eventClick={(event) => {
              this.setIsOpen(event, true);
            }}
            displayEventTime={true}
            title="Calendar Grid"
             viewDidMount= {this.handleViewMount}
          />
        )}

        <Dialog
          heading="Event Details"
          open={this.state.ModalState}
          onHeAfterHide={
            // check the target before setting state, as components you slot inside (such as Dropdown)
            // may also emit this event and it will bubble up
            ({ target, currentTarget }) =>
              target === currentTarget ? this.setIsOpen(false) : null
          }
        >
          <hr></hr>
          <Label
            style={{ fontWeight: "bold", fontSize: "17px", marginRight: "5px" }}
          >
            Event Title :
          </Label>
          <Label style={{ fontSize: "17px" }}>
            {this.state.CLickedEvent?.title}
          </Label>
          <br></br>
          <Label
            style={{ fontWeight: "bold", fontSize: "17px", marginRight: "5px" }}
          >
            Country :
          </Label>
          <Label style={{ fontSize: "17px" }}>{this.state.CountryName}</Label>
          <br></br>
          <Label
            style={{ fontWeight: "bold", fontSize: "17px", marginRight: "5px" }}
          >
            Province :
          </Label>
          <Label style={{ fontSize: "17px" }}>{this.state.ProvinceName}</Label>
          <br></br>
          <Label
            style={{ fontWeight: "bold", fontSize: "17px", marginRight: "5px" }}
          >
            Scheme :
          </Label>
          <Label style={{ fontSize: "17px" }}>
            {this.state.CLickedEvent?.scheme}
          </Label>
          <br></br>
          <Label
            style={{ fontWeight: "bold", fontSize: "17px", marginRight: "5px" }}
          >
            Microsoft ETA Completion :
          </Label>
          <Label style={{ fontSize: "17px" }}>
            {this.state.CLickedEvent?.start?.substring(0, 10)}
          </Label>
          <br></br>
          <Label
            style={{ fontWeight: "bold", fontSize: "17px", marginRight: "5px" }}
          >
            Final Reporting Deadline :
          </Label>
          <Label style={{ fontSize: "17px" }}>
            {this.state.CLickedEvent?.finalReportingDeadline}
          </Label>
          <br></br>
          <Label
            style={{ fontWeight: "bold", fontSize: "17px", marginRight: "5px" }}
          >
            Notes :
          </Label>
          <Label style={{ fontSize: "17px", wordBreak: "break-word" }}>
            {this.state.CLickedEvent?.notes}
          </Label>
          <br></br>

          <Button
            slot="footer"
            appearance="primary"
            onClick={() => this.setIsOpen(false)}
          >
            Ok
          </Button>
          <Button slot="footer" onClick={() => this.setIsOpen(false)}>
            Close
          </Button>
        </Dialog>
        <Spinner ShowSpinner={this.props.isSpinner}></Spinner>
      </div>
    );
  }
}

export default connect(mapStateToProps, ActionCreator)(Events);
