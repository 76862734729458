import {
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE,
  SET_EVENT_DATES,
  SET_SPINNER_EVENTTAB
} from "../actions/EventActionTypes";

const initialState = {
  loading: false,
  events: [],
  error: "",
  dateDetails: {
    startDate: "",
    endDate: "",
  },
  isSpinner: false,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload,
        error: "",
      };
    case FETCH_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        events: [],
        error: action.payload,
      };
    case SET_EVENT_DATES:
      return {
        ...state,
        dateDetails: action.payload,
      };
    case SET_SPINNER_EVENTTAB:
      return {
        ...state,
        isSpinner: action.payload,
      };
    default:
      return state;
  }
};

export default eventReducer;
