import { useEffect } from "react";
import React from "react";
import "../styles/ecstCommon.css"
import {
  createScope,
  partnerCenterTheme,
  dialog,
  button,
  textField,
  select,
  option,
  textArea,
  text,
  datePicker
} from "@harmony/enablers/react";

const scope = createScope({
  reactInstance: React,
  theme: partnerCenterTheme,
});

const Dialog = scope.forReact(dialog);
const Button = scope.forReact(button);
const TextField = scope.forReact(textField);
const Select = scope.forReact(select);
const Option = scope.forReact(option);
const TextArea = scope.forReact(textArea);
const Text = scope.forReact(text);
const DatePicker = scope.forReact(datePicker);

const AddEditCalAdminDialog = (props) => {

  const evenRef = React.useRef(null);
  const reptYearRef = React.useRef(null);
  const regionRef = React.useRef(null);
  const countryRef = React.useRef(null);
  const compCodeRef = React.useRef(null);
  const schemeRef = React.useRef(null);
  const LOBRef = React.useRef(null);
  const reptFreqRef = React.useRef(null);
  const reptDeadlineRef = React.useRef(null);
  const msETACompletionRef = React.useRef(null);
  const msFinalDeadlineRef = React.useRef(null);
  const reptStatusRef = React.useRef(null);


  useEffect(() => {
    regionRef.current?.setCustomValidity("");
    countryRef.current?.setCustomValidity("");
    reptStatusRef.current?.setCustomValidity("");
    console.log(props);
  }, [props.isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.inEditMode) {
      props.onEdit();
    } else {
      props.onSave();
    }
  };

  return (
    <>
      <Dialog
        heading={props.inEditMode ? "Edit Row" : "Add New Row"}
        style={{ "--width": "400px" }}
        open={props.isOpen}
        onHeAfterHide={() => props.handleCloseDialog()}
      >
        <form onSubmit={handleSubmit}>
          <Text appearance="paragraph" style={{ color:"#e50000"}}>
            * Field is mandatory.
          </Text>

          <TextField
            label="Event Name"
            value={props.data.EventName}
            name="EventName"
            type="text"
            onHeChange={props.onFormControlChange}
            ref={evenRef}
            required
            maxlength="100"
          />

          <TextField
            label="Reporting Year"
            value={props.data.ReportingPeriod}
            name="ReportingPeriod"
            type="number"
            onHeChange={props.onFormControlChange}
            ref={reptYearRef}
            required
            maxlength="4"
          />

          <Select
            label="Region"
            autocomplete="both"
            value={props.data.Region}
            name="Region"
            onHeChange={props.onFormControlChange}
            ref={regionRef}
            required
          >
            {props.RegionList.map((region) => {
              return (
                <Option key={region.RegionId} data-regionid={region.RegionId}>
                  {region.RegionName}
                </Option>
              );
            })}
          </Select>

          <Select
            label="Country"
            autocomplete="both"
            value={props.data.Country}
            name="Country"
            onHeChange={props.onFormControlChange}
            required
            ref={countryRef}
            disabled={props.CountryList.length == 0 ? true : false}
          >
            {props.CountryList.map((country) => {
              return (
                <Option
                  key={country.CountryId}
                  data-countryid={country.CountryId}
                >
                  {country.CountryName}
                </Option>
              );
            })}
          </Select>

          <Select
            label="Province"
            autocomplete="both"
            value={props.data.Province}
            name="Province"
            onHeChange={props.onFormControlChange}
            disabled={props.ProvinceList.length == 0 ? true : false}
          >
            {props.ProvinceList.map((region) => {
              return (
                <Option key={region.ProvinceId}>{region.ProvinceName}</Option>
              );
            })}
          </Select>

          <TextField
            label="Company Code"
            type="number"
            value={props.data.CompanyCode}
            name="CompanyCode"
            onHeChange={props.onFormControlChange}
            ref={compCodeRef}
            required
            maxlength="10"
          />

          <TextField
            label="Scheme"
            value={props.data.Scheme}
            name="Scheme"
            type="text"
            onHeChange={props.onFormControlChange}
            ref={schemeRef}
            required
            maxlength="35"
          />


          <TextField
            label="Reporting LOB"
            value={props.data.ReportingLOB}
            name="ReportingLOB"
            type="text"
            ref={LOBRef}
            onHeChange={props.onFormControlChange}
            required
            maxlength="35"
          />

          <TextField
            label="Compliance Org Name"
            value={props.data.OrgName}
            name="OrgName"
            type="text"
            onHeChange={props.onFormControlChange}
            maxlength="100"
          />
          <TextField
            label="Reporting Frequency"
            name="ReportingFrequency"
            type="text"
            value={props.data.ReportingFrequency}
            onHeChange={props.onFormControlChange}
            ref={reptFreqRef}
            required
            maxlength="100"
          />
          {props.inEditMode ? <DatePicker lang="en-IN" value={props.data.ReportingDeadline} ref={reptDeadlineRef} required label="Reporting Deadline" name="ReportingDeadline" onHeChange={props.onFormControlChange} onHeInput={props.onFormControlChange}></DatePicker> : <DatePicker lang="en-IN" ref={reptDeadlineRef} required label="Reporting Deadline" name="ReportingDeadline" onHeChange={props.onFormControlChange}   onHeInput={props.onFormControlChange}></DatePicker>}
          {props.inEditMode ? <DatePicker lang="en-IN" value={props.data.MicrosoftETACompletion} ref={msETACompletionRef} required label="MS ETA Completion" name="MicrosoftETACompletion" onHeChange={props.onFormControlChange} onHeInput={props.onFormControlChange}></DatePicker> : <DatePicker lang="en-IN" ref={msETACompletionRef} required label="MS ETA Completion" name="MicrosoftETACompletion" onHeChange={props.onFormControlChange}  onHeInput={props.onFormControlChange}></DatePicker>}
          {props.inEditMode ? <DatePicker lang="en-IN" value={props.data.MicrosoftFinalDeadline} ref={msFinalDeadlineRef} required label="MS Final Deadline" name="MicrosoftFinalDeadline" onHeChange={props.onFormControlChange} onHeInput={props.onFormControlChange}></DatePicker> : <DatePicker lang="en-IN" ref={msFinalDeadlineRef} required label="MS Final Deadline" name="MicrosoftFinalDeadline" onHeChange={props.onFormControlChange}  onHeInput={props.onFormControlChange}></DatePicker>}

          <Select
            label="Reporting Status"
            autocomplete="both"
            value={props.data.ReportingStatus}
            name="ReportingStatus"
            onHeChange={props.onFormControlChange}
            ref={reptStatusRef}
            required
          >
            {props.ReportingStatusList.map((status) => {
              return (
                <Option key={status.ReportingStatusId}>{status.Status}</Option>
              );
            })}
          </Select>


          <TextField
            label="Location"
            type="text"
            value={props.data.Location}
            name="Location"
            onHeChange={props.onFormControlChange}
            maxlength="100"
          />

          {props.inEditMode && (props.data.AuditSchedule != null && props.data.AuditSchedule != '')? <DatePicker lang="en-IN" value={props.data.AuditSchedule } label="Audit Schedule" name="AuditSchedule" onHeChange={props.onFormControlChange} onHeClear={props.onFormControlChange} onHeInput={props.onFormControlChange}></DatePicker> : <DatePicker lang="en-IN" label="Audit Schedule" name="AuditSchedule" onHeChange={props.onFormControlChange} onHeClear={props.onFormControlChange} onHeInput={props.onFormControlChange}></DatePicker>}

          <Select
            label="Audit Status"
            autocomplete="both"
            value={props.data.AuditStatus}
            name="AuditStatus"
            onHeChange={props.onFormControlChange}
          >
            {props.AuditStatusList.map((status) => {
              return (
                <Option key={status.AuditStatusId}>{status.Status}</Option>
              );
            })}
          </Select>

          <TextArea label="Notes"
            value={props.data.Notes}
            type="text"
            name="Notes"
            onHeChange={props.onFormControlChange}
            maxlength="1000"
          />

          <div slot="footer" style={{ marginTop: "20px" }}>
            <div style={{ float: 'right' }}>
              <Button appearance="primary" type="submit">
                Save
              </Button>

              <Button onClick={() => { props.handleCloseDialog(); }}>
                Close
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  )
};

export default AddEditCalAdminDialog;