import React from "react";
import { createScope, button } from '@harmony/enablers/react';

const scope = createScope({
    reactInstance: React
});

const Button = scope.forReact(button);

const Home = () => {
    return (
        <div style={{ color: "white", textAlign: "center", height: 400, paddingTop: "10%", paddingBottom:"15%",marginLeft: "40px", marginRight: "40px", marginBottom: "50px", backgroundColor: "rgb(50, 118, 177)", borderRadius: "25px", }}>
              < div id="navigation-announcer" role="alert" aria-live="polite">Navigated to Home Page</div>
            <h1>Microsoft Application</h1>
            <h4>* WEEE, Battery, Packaging and Print *</h4>
            <p style={{wordWrap:"true",margin:"10px"}}>
                This application, developed and maintained by Microsoft, stores key information that allows Microsoft and its contracted partners to fulfil its global regulatory reporting obligations. Please contact <a style={{color:"white"}} href="mailto:weee@microsoft.com">weee@microsoft.com</a> for any questions you might have.
            </p>
        </div>
    )
}

export default Home;